import parse from 'date-fns/parse';
import React from 'react';

import DataField from '@/components/DataField';

import {DateFieldConfig} from '@/generalization/definitions';
import {formatDate} from '@/utils/format';

import {FieldProps} from '.';

export default function DateField({config, wrapIn}: FieldProps<DateFieldConfig>) {
  if (config.value == null || config.value.trim().length === 0) {
    return null;
  }

  const date = parse(config.value);

  if (config.minDays) {
    const pastDate = new Date();
    // Use +1 to catch everything until midnight and ignore hours.
    pastDate.setDate(pastDate.getDate() - config.minDays + 1);
    pastDate.setHours(0, 0, 0, 0);
    if (pastDate < date) {
      return null;
    }
  }

  const field = (
      <DataField copyText={config.value} label={config.label} description={config.description}>
        {formatDate(config.value, config.dateFormat)}
      </DataField>
  );

  if (wrapIn) {
    return wrapIn(field);
  }

  return field;
}
