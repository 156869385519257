import React from 'react'
import {Notifications} from "@liquid-design/liquid-design-react";

export interface NotificationsWrapper {
  notificationElement: any
}

export const NotificationContext = React.createContext<NotificationsWrapper | null>(null);

export function NotificationContextProvider(props: any) {
  const notifications: NotificationsWrapper = {notificationElement: null};

  return <NotificationContext.Provider value={notifications}>
    <Notifications style={{
      zIndex: 2 /*is needed because of liqued design error so header has 1 and notifications needed 2*/
    }} autoRemoveTimeout={3000}
                   ref={(v: any) => {
                     notifications.notificationElement = v;
                   }}/>
    {props.children}
  </NotificationContext.Provider>
}
