import React from 'react';
import ExplorerNavbar from './components/ExplorerNavbar';
import {ConfigContextProvider} from './contexts/config';
import {LoadingContextProvider} from "./contexts/loading";
import {NotificationContextProvider} from "./contexts/notification";
import ModalsController from './controllers/ModalsController';
import {GlobalStyle} from "@/styles/GlobalStyle";
import {Routes} from "@/Routes";
import {AuthorizationContextProvider} from "@/contexts/authorization";
import {GraphQLContextProvider} from "@/contexts/graphQl";


export function App() {
    return (
        <>
            <GlobalStyle/>
            <ModalsController>
                <ConfigContextProvider>
                    <AuthorizationContextProvider>
                        <GraphQLContextProvider>
                            <NotificationContextProvider>
                                <LoadingContextProvider>
                                    <ExplorerNavbar>
                                        <Routes/>
                                    </ExplorerNavbar>
                                </LoadingContextProvider>
                            </NotificationContextProvider>
                        </GraphQLContextProvider>
                    </AuthorizationContextProvider>
                </ConfigContextProvider>
            </ModalsController>
        </>
    );
}
