import React, {createContext, useCallback, useContext} from "react";
import {InteractionType} from "@azure/msal-browser";
import {useMsal, useMsalAuthentication} from "@azure/msal-react";
import {AppConfigurationContext} from "@/contexts/appConfig";

const SCOPE = "/User.Read"

export interface Authorization {
    getAccessToken: () => Promise<string>
}

export const AuthorizationContext = createContext<Authorization>(null);

export function AuthorizationContextProvider({children}: { children: React.ReactNode }) {

    const {appUri} = useContext(AppConfigurationContext);

    useMsalAuthentication(InteractionType.Redirect, {
        scopes: [appUri + SCOPE]
    });

    const {instance, accounts} = useMsal();

    const getAccessToken = useCallback(() => {
        const accessTokenRequest = {
            scopes: [appUri + SCOPE],
            account: accounts[0],
        };
        return instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => accessTokenResponse.accessToken);

    }, [instance, accounts]);

    return <AuthorizationContext.Provider value={{getAccessToken}}>
        {children}
    </AuthorizationContext.Provider>
}
