import React from 'react';
import styled from 'styled-components';

import { Avatar } from './Avatar';

const Wrapper = styled.div`
  padding: 1rem 0;
  text-align: center;
`;

const Greeting = styled.h4`
  margin-bottom: 15px;
`;

const AvatarWrapper = styled.span`
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
`;

type Props = {
  name: string;
  avatarSrc: string;
  initials: string;
  email: string;
  greeting?: string;
};

const Email = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
`;

/**
 * Displays avatar, name, email address and optional greeting.
 */
export function WelcomePanel({ name, email, avatarSrc, initials, greeting }: Props) {
  return (
    <Wrapper>
      {greeting && <Greeting>{greeting}</Greeting>}
      <AvatarWrapper>
        <Avatar src={avatarSrc} initials={initials} size="6rem" />
      </AvatarWrapper>
      <div>
        <h3>{name}</h3>
        <Email>{email}</Email>
      </div>
    </Wrapper>
  );
}

export default WelcomePanel;
