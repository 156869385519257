import { Button, Modal } from '@liquid-design/liquid-design-react';
import React, { useState } from 'react';
import styled from 'styled-components';

import DataField from '@/components/DataField';
import { TruncatedText } from '@/components/TruncatedText';
import { MultilineFieldConfig } from '@/generalization/definitions';
import { t } from '@/i18n';

import { FieldProps } from '.';
import './MultilineField.scss';

type Props = FieldProps<MultilineFieldConfig>;

const MAX_INLINE_VALUES = 3;

const ShowMoreButton = styled(Button)`
  padding: 0;

  > * {
    margin: 0;
  }
`;

export default function MultilineField({ config, wrapIn }: Props) {
  const [modalOpen, setModalOpen] = useState(false);

  function renderShowMore() {
    return (
      <>
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          className="giam--multiline-field-modal"
          label={config.label}
        >
          <ul className="data-item-list">
            {values.map((value, i) => (
              <li className="data-item" key={i}>
                {value}
              </li>
            ))}
          </ul>
        </Modal>
        <div>
          <ShowMoreButton appearance="ghost" onClick={() => setModalOpen(true)}>
            {t('fields.multiline_field.show_all_items', { count: values.length })}
          </ShowMoreButton>
        </div>
      </>
    );
  }

  // get values and filter empty ones
  const values = config.value.filter(val => !!val).sort();

  if (!values || values.length === 0) {
    return null;
  }

  const field = (
    <DataField
      label={config.label}
      description={config.description}
      truncate={false}
      className="giam--multiline-field"
    >
      {values.slice(0, MAX_INLINE_VALUES).map((value, i) => (
        <TruncatedText key={i}>{value}</TruncatedText>
      ))}
      {values.length > MAX_INLINE_VALUES && renderShowMore()}
    </DataField>
  );

  if (wrapIn) {
    return wrapIn(field);
  }

  return field;
}
