import React from 'react';

import Container from '@/components/Container';

import { parseQueryString } from '@/utils/queryString';

interface ErrorPageProps {
  title: string;
  children?: React.ReactNode;
}

export default function ErrorPage({ title, children }: ErrorPageProps) {
  const message = parseQueryString(window.location.search).message;

  return (
    <Container>
      <div>
        <h1>{title}</h1>
        {message && <p>{message}</p>}
        {children}
      </div>
    </Container>
  );
}
