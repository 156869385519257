import {Badge, Headline} from '@liquid-design/liquid-design-react';
import React from 'react';
import styled from 'styled-components';

import Panel, {PanelHeader, PanelSubtitle, PanelTitle} from '../Panel';

import {FieldConfig, PanelWithImageConfig} from '@/generalization/definitions';
import {t} from '@/i18n';
import {formatInitials} from '@/utils/format';

import {PanelProps} from '.';
import {Avatar} from '../Avatar';
import {FieldColumn, FieldGrid} from '../FieldGrid';
import {IconButton} from '../IconButton';

import Field from './Field';
import {useImageLoader} from "@/components/useImageLoader";

const AvatarContainer = styled.div`
  padding: 24px;
  text-align: center;
`;



const Icon = styled.div`
  text-align: right;
`;

interface IGroupedFieldConfig {
  [key: string]: FieldConfig[];
}

type Props = PanelProps<PanelWithImageConfig>;

function PanelWithImage({config}: Props) {

  function InstantMessagingService() {
    if (window.location.pathname !== "/identities/me/data") {
      return<Icon>
        <IconButton iconName={"teams"} href={`msteams:/l/chat/0/0?users=${config.email}`}/>
      </Icon>;
    }
    return null;
  }

  function renderGroupedFields() {
    const groups: IGroupedFieldConfig = config.fields.reduce(
      (g: IGroupedFieldConfig, field: FieldConfig) => {
        const groupName: string = field.group ? field.group : '__ungrouped__';
        return {...g, [groupName]: [...(g[groupName] || []), field]};
      },
      {},
    );

    return Object.keys(groups).map((key: string, index: number) => (
      <React.Fragment key={index}>
        {key === '__ungrouped__' ? null : <Headline type="H4">{t(key)}</Headline>}
        <FieldGrid>
          {groups[key].map((field, i) => (
            <Field wrapIn={f => <FieldColumn>{f}</FieldColumn>} key={i} config={field}/>
          ))}
        </FieldGrid>
      </React.Fragment>
    ));
  }

  const avatarUrl = useImageLoader(config.imageId, config.imageSize);

  return (
    <Panel>
      <PanelHeader>
        <PanelTitle>{config.title}</PanelTitle>

        <InstantMessagingService/>

        <PanelSubtitle>{config.subtitle}</PanelSubtitle>

      </PanelHeader>

      <AvatarContainer>
          <Avatar size="6rem" src={avatarUrl} initials={formatInitials(config.imageInitials)}/>
        {config.imageTitle && (
          <p>
            <b>{config.imageTitle}</b>
            {config.imageBadge && (
              <>
                <br/>
                <Badge>{config.imageBadge}</Badge>
              </>
            )}
          </p>
        )}
        {config.imageSubtitle && <p>{config.imageSubtitle}</p>}
      </AvatarContainer>

      {renderGroupedFields()}
    </Panel>
  );
}

export default PanelWithImage;
