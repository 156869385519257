import format from 'date-fns/format';
import parse from 'date-fns/parse';

/**
 * Formats a date string.
 * @param dateStr A date string.
 * @return The formatted date or null if dateStr is falsy.
 */
export function formatDate(dateStr: string | Date, dateFormat: string = 'YYYY-MM-DD') {
  if (!dateStr) {
    return null;
  }

  return format(parse(dateStr), dateFormat);
}

/**
 * Formats a name of a person.
 * @param names All parts of the name. Null undefined and empty strings are omitted.
 * @return The full name or null if all names of the name are falsy.
 */
export function formatName(...names: string[]) {
  const filteredNames = names.filter(namePart => Boolean(namePart));
  if (filteredNames.length === 0) {
    return null;
  }

  return filteredNames.join(' ');
}

/**
 * Formats initials of a person.
 * @param names All parts of the name. Null undefined and empty strings are omitted.
 * @return The intials or null if all names of the name are falsy.
 */
export function formatInitials(...names: string[]) {
  const splittedNames: string[] = [];
  names.forEach(name => {
    if (name) {
      splittedNames.push(...name.split(' '));
    }
  });

  const filteredNames = splittedNames.filter(namePart => Boolean(namePart));
  if (filteredNames.length === 0) {
    return null;
  }

  return filteredNames.map(name => name.charAt(0)).join('');
}
