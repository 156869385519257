import {HistoryRouterController} from './HistoryRouterController';

let stack: string[] = [];

/**
 * Push a route to the 'hirachy' stack and navigate there using the HistoryRouterController.
 * @param route the path of the route to add
 */
export function pushStack(route: string, skipNotify: boolean) {
  // push old route to stack
  stack.push(HistoryRouterController.getCurrentPath());

  // navigate to new route
  HistoryRouterController.pushRoute(route, {stack}, skipNotify);
}

/**
 * Replace the top-most route in the 'hirachy' stack and navigate there using the HistoryRouterController.
 * @param route the path of the route to add
 */
export function replaceStack(route: string) {
  // navigate to new route
  HistoryRouterController.replaceRoute(route, {stack});
}

/**
 * Pop a route from the 'hirachy' stack and navigate there using the HistoryRouterController.
 * @param defaultRoute the path of the route to use when there is none in the stack
 */
export function popStack(defaultRoute: string) {
  // pop parent route from stack
  const route = stack.pop();

  // navigate to parentRoute or default Route
  HistoryRouterController.pushRoute(route || defaultRoute, {stack});
}

HistoryRouterController.addRouteChangeHandler((path, state) => {
  // restore stack from history state
  if (state && state.stack) {
    stack = state.stack;
  } else {
    stack = [];
  }
});

/**
 * Checks if there are routes in the stack.
 */
export function hasStack() {
  return stack.length > 0;
}
