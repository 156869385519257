import React from "react";
import {UserDataContextProvider} from "@/contexts/userData";
import {IRoute, IRouterController, Router} from "@/components/Router/Router";
import ErrorPage from "@/containers/ErrorPage/ErrorPage";
import ErrorPage401 from "@/containers/ErrorPage/ErrorPage401";
import IdentitySearch from "@/containers/IdentitySearch/IdentitySearch";
import IdentityDetails from "@/containers/IdentityPage/Overview/IdentityDetails";
import IdentityDetailsData from "@/containers/IdentityPage/PersonalInformation/IdentityDetailsData";
import IdentityDetailsEquipment from "@/containers/IdentityPage/Equipment/IdentityDetailsEquipment";
import IdentityDetailsAccounts from "@/containers/IdentityPage/Accounts/IdentityDetailsAccounts";
import IdentityDetailsAccountabilities
    from "@/containers/IdentityPage/Accountabilities/IdentityDetailsAccountabilities";
import IdentityDetailsTeam from "@/containers/IdentityPage/Team/IdentityDetailsTeam";
import IdentityDetailsDelegatedTeam from "@/containers/IdentityPage/Team/IdentityDetailsDelegatedTeam";
import {NotFound} from "@/containers/NotFound";
import {HistoryRouterController} from "@/components/Router/HistoryRouterController";
import {useIsAuthenticated} from "@azure/msal-react";


function withUserDataLoader(Component: any) {
    return (props: any) => <UserDataContextProvider>{<Component {...props} />}</UserDataContextProvider>;
}


const routes: IRoute[] = [
    {
        // redirect legacy URL ('explorer/' prefix) to new URL
        path: 'explorer/**',
        handle: [
            (controller: IRouterController) => {
                controller.replaceRoute('identities/me');
            },
        ],
    },
    {
        path: '',
        handle: [
            (controller: IRouterController) => {
                controller.replaceRoute('identities/me');
            },
        ],
    },
    {
        path: 'error',
        handle: [() => ErrorPage],
    },
    {
        path: 'error/401',
        handle: [() => ErrorPage401],
    },
    {
        path: 'error/:code',
        handle: [() => ({params}: { params: any }) => <ErrorPage title={params.code}/>],
    },
    {
        path: 'search',
        handle: [
            () =>
                withUserDataLoader(({query}: { query: any }) => <IdentitySearch searchTerm={query.q}/>),
        ],
    },
    {
        path: 'identities/:uuid',
        handle: [
            () =>
                withUserDataLoader(({params}: { params: any }) => (
                    <>
                        <IdentityDetails uuid={params.uuid}/>
                        {/* <WelcomeModal /> */}
                    </>
                )),
        ],
    },
    {
        path: 'identities/:uuid/data',
        handle: [
            () =>
                withUserDataLoader(({params}: { params: any }) => (
                    <IdentityDetailsData params={params}/>
                )),
        ],
    },
    {
        path: 'identities/:uuid/overview',
        handle: [
            () =>
                withUserDataLoader(({params}: { params: any }) => (
                    <IdentityDetailsData params={params} overview/>
                )),
        ],
    },
    {
        path: 'identities/:uuid/equipment',
        handle: [
            () =>
                withUserDataLoader(({params}: { params: any }) => (
                    <IdentityDetailsEquipment params={params}/>
                )),
        ],
    },
    {
        path: 'identities/:uuid/accounts',
        handle: [
            () =>
                withUserDataLoader(({params}: { params: any }) => (
                    <IdentityDetailsAccounts params={params}/>
                )),
        ],
    },
    {
        path: 'identities/:uuid/accountabilities',
        handle: [
            () =>
                withUserDataLoader(({params}: { params: any }) => (
                    <IdentityDetailsAccountabilities params={params}/>
                )),
        ],
    },
    {
        path: 'identities/:uuid/team',
        handle: [
            () =>
                withUserDataLoader(({params}: { params: any }) => (
                    <IdentityDetailsTeam params={params}/>
                )),
        ],
    },
    {
        path: 'identities/:uuid/delegatedTeam',
        handle: [
            () =>
                withUserDataLoader(({params}: { params: any }) => (
                    <IdentityDetailsDelegatedTeam params={params}/>
                )),
        ],
    },
    {
        path: '**',
        handle: [() => () => <NotFound/>],
    },
];

export function Routes() {
    const isAuthenticated = useIsAuthenticated();
    return <>
        {isAuthenticated && <Router routes={routes} controller={HistoryRouterController}/>}
    </>

}
