import React from 'react';
import styled from 'styled-components';

import { FittedText } from './FittedText';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  src: string;
  initials?: string;
  size: string;
};

const AvatarWrapper = styled.div<{ size: string }>`
  width: ${props => props.size};
  height: ${props => props.size};
  border-radius: 50%;
  background-size: contain;
  background-color: ${props => props.theme.colors.sensitiveGrey.dark};
  position: relative;
  display: inline-block;
  flex: 0 0 auto;
`;

const Initials = styled(FittedText)`
  color: #aaa;
`;

export function Avatar({ src, initials, size, ...props }: Props) {
  return (
    <AvatarWrapper
      size={size}
      style={{
        backgroundImage: src ? `url(${src})` : undefined,
      }}
      {...props}
    >
      {!src && <Initials>{initials}</Initials>}
    </AvatarWrapper>
  );
}
