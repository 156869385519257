import React from 'react';

import PersonList, { PersonItem } from '@/components/PersonList';
import { pushStack } from '@/components/Router/StackController';
import { formatInitials } from '@/utils/format';
import {useImageLoader} from "@/components/useImageLoader";

export function TeamMembers({ members, avatarSize }: { members: any[]; avatarSize: string }) {


  return (
    <PersonList>
      {members.map(({ uuid, name, email }) => <TeamMember key={uuid}
                                                          uuid={uuid}
                                                          name={name}
                                                          email={email}
                                                          avatarSize={avatarSize}/>
      )}
    </PersonList>
  );
}

interface ITeamMember{
  uuid: string,
  name: string,
  email: string,
  avatarSize: string
}

function TeamMember({uuid, name, email, avatarSize}:ITeamMember){

  const avatarUrl = useImageLoader(uuid, avatarSize);
  return <PersonItem
      title={name}
      imageSrc={avatarUrl}
      initials={formatInitials(name)}
      items={[email || '-']}
      onClick={() => {
        pushStack(`identities/${encodeURIComponent(uuid)}`);
      }}
  />
}
