import {createGlobalStyle} from "styled-components";

export const GlobalStyle = createGlobalStyle`
 * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    background: #F3F3F7;
  }

  a {
    color: #503191;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;
