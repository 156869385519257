import React from 'react'
import {useLoading} from "@/hooks/loading";
import {StyledLoader} from "@/styles/StyledLoader";
import LoadingSpinner from "@/components/LoadingSpinner";

export interface Loading {
  loading: boolean,
  setLoading: (value: boolean) => void
}

const LOADING_DEFAULT_VALUE: Loading = {
  loading: false,
  setLoading: () => {
  }
};

export const LoadingContext = React.createContext<Loading>(LOADING_DEFAULT_VALUE);

export function LoadingContextProvider(props: any){
  const loading = useLoading();
  return <LoadingContext.Provider value={loading}>
    <StyledLoader
        active={loading.loading}
        spinner={<LoadingSpinner/>}
        classNamePrefix='MyLoader_'
    >
      {props.children}
    </StyledLoader>
  </LoadingContext.Provider>
}
