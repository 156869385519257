import React from 'react';
import styled from 'styled-components';

import {Button} from '@liquid-design/liquid-design-react';


type Props = {
  iconName: string;
  href: string;
};

const IconWrapper = styled.span`
    margin-right: 6px;
`;

export function IconButton({iconName, href}: Props) {
  return (
    <IconWrapper>
      <a href={href}>
        <Button icon={iconName}/>
      </a>
    </IconWrapper>);
}

export default IconButton;
