import React from 'react';

import BackButton from '@/components/BackButton';

import { hasStack, popStack } from './Router/StackController';

export function DefaultBackButton({ defaultParentRoute = null }: { defaultParentRoute: string }) {
  if (!hasStack() && defaultParentRoute === null) {
    return null;
  }

  return <BackButton onClick={() => popStack(defaultParentRoute)} />;
}
