import { Headline } from '@liquid-design/liquid-design-react';
import React from 'react';
import styled from 'styled-components';

import { DefaultBackButton } from './DefaultBackButton';

export interface HeaderWithBackButtonProps {
  as: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'H6';
  title: string;
  defaultParentRoute: string;
}

const Wrapper = styled(Headline)`
  margin-bottom: 1.5rem;
`;

/**
 * A header with an integrated back button.
 */
export function HeaderWithBackButton({ as, defaultParentRoute, title }: HeaderWithBackButtonProps) {
  return (
    <>
      <DefaultBackButton defaultParentRoute={defaultParentRoute} />
      <Wrapper type={as}>{title}</Wrapper>
    </>
  );
}
