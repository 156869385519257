import React from 'react';

import Container from '@/components/Container';

export class NotFound extends React.Component {
  render() {
    return (
      <Container>
        <div>
          <h1>OOOPS. Something Went Wrong.</h1>

          <p>
            <b>The reason may be that:</b>
          </p>
          <p>
            Due to updates, the page has been moved or deleted. Or the URL in the browser address
            field is misspelled. Please check that it is spelled correctly.
          </p>
          <p>
            If you have any further questions do not hesitate to contact us by quoting the above
            mentioned error message and current URL.
          </p>
        </div>
      </Container>
    );
  }
}
