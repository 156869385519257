import React from 'react';

import DataField from '@/components/DataField';
import { BooleanFieldConfig } from '@/generalization/definitions';
import { t } from '@/i18n';

import { FieldProps } from '.';

export default function BooleanField({ config, wrapIn }: FieldProps<BooleanFieldConfig>) {
  if (config.value == null) {
    return null;
  }

  const trueText = config.trueText || t('fields.boolean_field.yes');
  const falseText = config.falseText || t('fields.boolean_field.no');

  const field = (
    <DataField label={config.label} description={config.description}>
      {config.value ? trueText : falseText}
    </DataField>
  );

  if (wrapIn) {
    return wrapIn(field);
  }

  return field;
}
