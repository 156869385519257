import React from 'react';
import styled from 'styled-components';

import DataField from '@/components/DataField';
import {AddressFieldConfig} from '@/generalization/definitions';

import {FieldProps} from '.';

const Address = styled.address`
  font-style: normal;
`;

export default function AddressField({config, wrapIn}: FieldProps<AddressFieldConfig>) {
  const lines = [
    config.nameValue,
    config.streetValue,
    `${config.zipValue || ''} ${config.cityValue || ''}`,
    config.countryValue,
  ].filter(line => line && line.trim());

  // if there are only empty lines -> hide address
  if (lines.every(line => !line)) {
    return null;
  }

  // TODO refactor "addr[key]Field" to use getFieldValues()
  const field = (
      <DataField label={config.label} truncate={false} description={config.description}>
        <a
            href={`https://www.google.com/maps/search/?api=1&query=${lines.join(' ')}`}
            rel="noopener"
            target="_blank"
        >
          <Address>
            {lines.map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br/>
                </React.Fragment>
            ))}
          </Address>
        </a>
      </DataField>
  );

  if (wrapIn) return wrapIn(field);

  return field;
}
