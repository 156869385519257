import React, { createContext } from 'react';

import { Omit } from '@/types/omit';

export type ModalsProp = {
  welcomeModal: {
    isOpen: boolean;
    show: () => void;
    hide: () => void;
  };
};

const ModalsContext = createContext<ModalsProp>({
  welcomeModal: {
    isOpen: false,
    show: () => {},
    hide: () => {},
  },
});

export const { Provider: ModalsProvider, Consumer: ModalsConsumer } = ModalsContext;

export function withModals<Props extends { modalsController: ModalsProp }>(
  Component: React.ComponentType<Props>,
) {
  return (props: Omit<Props, 'modalsController'>) => (
    <ModalsConsumer>
      {modalsController => <Component {...{ ...props, modalsController } as Props} />}
    </ModalsConsumer>
  );
}

export default ModalsContext;
