import React, {createContext, ReactNode, useCallback, useState} from 'react';

import {DataLoader} from "@/components/DataLoader";
import {t} from "@/i18n";
import {get} from "@/backend";

export interface IdentityDetailsConfig {
    overview: {
        _query: string;
        fullName: string;
        firstName: string;
        lastName: string;
        email: string;
    };
    team: {
        _query: string;
    };
    delegatedTeam: {
        _query: string;
    };
    personalInformation: {
        _query: string;
    };
    data: {
        _query: string;
    };
    equipment: {
        _query: string;
    };
    accounts: {
        _query: string;
    };
    accountabilities: {
        _query: string;
    };
}

export interface UserConfig {
    _query: string;
    fullName: string;
    mlid: string;
    uuid: string;
}

export interface AppConfig {
    identity: IdentityDetailsConfig;
    userInfo: UserConfig;
}


export const ConfigContext = createContext<AppConfig>(null);


export function ConfigContextProvider({children}: { children: ReactNode }) {

    const [config, setConfig] = useState<AppConfig>(null);

    const loadConfig = useCallback(async (signal: AbortSignal): Promise<void> => {
        try {
            const {res, body} = await get('graphql/config', {signal});
            if (!res.ok) throw new Error(body.message);

            setConfig(body)
        } catch (e) {
            throw new Error(t('common.network_error'));
        }
    }, [setConfig])


    return (
        <DataLoader action={({signal}) => loadConfig(signal)} dataLoaded={config != null}>
            {() => {
                return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
            }}
        </DataLoader>
    );
}
