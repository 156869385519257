import React from 'react';

import { TeamMembers } from './TeamMembers';
import Container from '@/components/Container';
import { HeaderWithBackButton } from '@/components/HeaderWithBackButton';
import LoadingSpinner from '@/components/LoadingSpinner';
import NoEntries from '@/components/NoEntries';
import SectionTitle from '@/components/SectionTitle';
import { useUserData } from '@/contexts/userData';
import { useOverviewData, useTeamMembers } from '@/hooks/configuredData';
import { t } from '@/i18n';

type Props = {
  // router
  params: any;
};

function IdentityDetailsTeam({ params }: Props) {
  const userData = useUserData();

  const isSelf = () => {
    return params.uuid === 'me';
  };

  const defaultParentRoute = () => {
    return isSelf() ? null : '';
  };

  const uuid = isSelf() ? userData.uuid : params.uuid;

  const { data } = useOverviewData(uuid);
  const { data: teamData, query: teamQuery } = useTeamMembers(uuid);

  function renderInner() {
    if (teamQuery.loading) return <LoadingSpinner />;

    if (teamData.internalMembers.length + teamData.externalMembers.length === 0) {
      return <NoEntries absolute>No team members.</NoEntries>;
    }

    return (
      <>
        {teamData.internalMembers.length > 0 && (
          <>
            <SectionTitle type="H4">{t('identity_details.internal_teammembers')}</SectionTitle>
            <TeamMembers members={teamData.internalMembers} avatarSize="120x120" />
          </>
        )}
        {teamData.externalMembers.length > 0 && (
          <>
            <SectionTitle type="H4">{t('identity_details.external_teammembers')}</SectionTitle>
            <TeamMembers members={teamData.externalMembers} avatarSize="120x120" />
          </>
        )}
      </>
    );
  }

  return (
    <Container>
      <HeaderWithBackButton
        as="H2"
        title={
          isSelf()
            ? t('identity_details.my_team')
            : t('identity_details.team_of', { name: data.fullName })
        }
        defaultParentRoute={defaultParentRoute()}
      />
      {renderInner()}
    </Container>
  );
}

export default IdentityDetailsTeam;
