import React from 'react';

import Container from '@/components/Container';
import DynamicPanel from '@/components/DynamicPanel';
import { HeaderWithBackButton } from '@/components/HeaderWithBackButton';
import LoadingSpinner from '@/components/LoadingSpinner';
import NoEntries from '@/components/NoEntries';
import { PanelGroup } from '@/components/Panel';
import SectionTitle from '@/components/SectionTitle';
import { useUserData } from '@/contexts/userData';
import { useEquipmentData } from '@/hooks/configuredData';
import { t } from '@/i18n';
import { flatten } from '@/utils/flatten';

interface IIdentityDetailsEquipmentProps {
  params: any;
}

function renderStructuredPanelGroup(panels: any[]) {
  const groups: { [key: string]: any } = {};
  panels.forEach((panel: any) => {
    const groupName = panel.group || '__ungrouped__';

    if (!groups[groupName]) {
      groups[groupName] = [];
    }

    groups[groupName].push(panel);
  });

  return Object.keys(groups).map(key => {
    const group = groups[key];

    return (
      <React.Fragment key={key}>
        <SectionTitle type="H4">{t(key)}</SectionTitle>
        <PanelGroup>
          {group.map((panel: any, i: number) => (
            <DynamicPanel key={i} config={panel} />
          ))}
        </PanelGroup>
      </React.Fragment>
    );
  });
}

function IdentityDetailsEquipmentComponent({ params }: IIdentityDetailsEquipmentProps) {
  const userData = useUserData();
  const identityUuid = () => {
    return params.uuid === 'me' ? userData.uuid : params.uuid;
  };

  const { data, query } = useEquipmentData(identityUuid());
  const panels = flatten(data.panels);

  return (
    <Container>
      <HeaderWithBackButton
        as="H2"
        // Hide title until owner name is loaded
        title={data.owner && t('identity_details_equipment.equipment_of', { owner: data.owner })}
        defaultParentRoute={`identities/${params.uuid}`}
      />
      {query.loading && <LoadingSpinner />}
      {!query.loading &&
        (panels && panels.length > 0 ? (
          renderStructuredPanelGroup(panels)
        ) : (
          <NoEntries absolute>{t('identity_details_equipment.no_equipment')}</NoEntries>
        ))}
    </Container>
  );
}

export default IdentityDetailsEquipmentComponent;
