import { useRef } from 'react';

export function useDebounceCallback<T>(callback: (value: T) => void, delay = 400) {
  const timeoutRef = useRef(null);

  return (input: T) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      callback(input);
    }, delay);
  };
}
