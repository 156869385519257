import React from 'react';

import Panel, { PanelGroup, PanelHeader, PanelSubtitle, PanelTitle } from '@/components/Panel';
import { pushStack } from '@/components/Router/StackController';

import { t } from '@/i18n';

type Props = {
  uuid: string;
};

function DetailsPanel({ uuid }: Props) {
  return (
    <PanelGroup>
      <Panel>
        <PanelHeader
          clickable
          decoration="arrowRight"
          onClick={e => {
            pushStack(`identities/${uuid}/data`);
            e.preventDefault();
          }}
        >
          <PanelTitle>{t('identity_details.personal_information')}</PanelTitle>
          <PanelSubtitle />
        </PanelHeader>
      </Panel>
      <Panel>
        <PanelHeader
          clickable
          decoration="arrowRight"
          onClick={e => {
            pushStack(`identities/${uuid}/equipment`);
            e.preventDefault();
          }}
        >
          <PanelTitle>{t('identity_details.equipment')}</PanelTitle>
          <PanelSubtitle />
        </PanelHeader>
      </Panel>
      <Panel>
        <PanelHeader
          clickable
          decoration="arrowRight"
          onClick={e => {
            pushStack(`identities/${uuid}/accounts`);
            e.preventDefault();
          }}
        >
          <PanelTitle>{t('identity_details.accounts_and_authorizations')}</PanelTitle>
          <PanelSubtitle />
        </PanelHeader>
      </Panel>
      <Panel>
        <PanelHeader
          clickable
          decoration="arrowRight"
          onClick={e => {
            pushStack(`identities/${uuid}/accountabilities`);
            e.preventDefault();
          }}
        >
          <PanelTitle>{t('identity_details.accountabilities')}</PanelTitle>
          <PanelSubtitle />
        </PanelHeader>
      </Panel>
    </PanelGroup>
  );
}

export default DetailsPanel;
