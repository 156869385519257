import React from 'react';
// import styled from 'styled-components';

import Container from '@/components/Container';
import { HeaderWithBackButton } from '@/components/HeaderWithBackButton';
import LoadingSpinner from '@/components/LoadingSpinner';
import NoEntries from '@/components/NoEntries';
import SectionTitle from '@/components/SectionTitle';
import { useUserData } from '@/contexts/userData';
import { useDelegatedTeamMembers, useOverviewData } from '@/hooks/configuredData';
import { t } from '@/i18n';

import { TeamMembers } from './TeamMembers';

type Props = {
  // router
  params: any;
};

// type LoadMoreButtonProps = {
//   fetchMore: QueryResult['fetchMore'];
// };

// class LoadMoreButton extends React.Component<LoadMoreButtonProps, { loading: boolean }> {
//   constructor(props: LoadMoreButtonProps) {
//     super(props);

//     this.state = {
//       loading: false,
//     };
//   }

//   render() {
//     const { fetchMore } = this.props;
//     const { loading } = this.state;

//     if (loading) {
//       return <LoadingSpinner inline />;
//     }

//     return (
//       <div className="text-center">
//         <Button
//           onClick={() => {
//             this.setState({ loading: true });
//             fetchMore({
//               updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
//               variables: { limit: null },
//             }).then(() => {
//               this.setState({ loading: false });
//             });
//           }}
//         >
//           {t('identity_details.show_all')}
//         </Button>
//       </div>
//     );
//   }
// }

// const CenteredText = styled.div`
//   text-align: center;
//   margin-bottom: 2rem;
// `;

function IdentityDetailsDelegatedTeam({ params }: Props) {
  const userData = useUserData();

  const defaultParentRoute = () => {
    return isSelf() ? null : '';
  };

  const isSelf = () => {
    return params.uuid === 'me';
  };

  const uuid = isSelf() ? userData.uuid : params.uuid;
  const { data } = useOverviewData(uuid);
  const { data: teamData, query: teamQuery } = useDelegatedTeamMembers(uuid);

  const renderInner = () => {
    if (teamQuery.loading) return <LoadingSpinner />;

    if (teamData.internalMembers.length + teamData.externalMembers.length === 0) {
      return <NoEntries absolute>No delegated team members.</NoEntries>;
    }

    return (
      <>
        {teamData.internalMembers.length > 0 && (
          <>
            <SectionTitle type="H4">{t('identity_details.internal_teammembers')}</SectionTitle>
            <TeamMembers members={teamData.internalMembers} avatarSize="120x120" />
            {/* <CenteredText>
              {hasMoreInternal && <LoadMoreButton fetchMore={internalFetchMore} />}
            </CenteredText> */}
          </>
        )}
        {teamData.externalMembers.length > 0 && (
          <>
            <SectionTitle type="H4">{t('identity_details.external_teammembers')}</SectionTitle>
            <TeamMembers members={teamData.externalMembers} avatarSize="120x120" />
            {/* <CenteredText>
              {hasMoreExternal && <LoadMoreButton fetchMore={externalFetchMore} />}
            </CenteredText> */}
          </>
        )}
      </>
    );
  };

  return (
    <Container>
      <HeaderWithBackButton
        as="H2"
        title={
          isSelf()
            ? t('identity_details.my_delegated_team')
            : t('identity_details.delegated_team_of', { name: data.fullName })
        }
        defaultParentRoute={defaultParentRoute()}
      />
      {renderInner()}
    </Container>
  );
}

export default IdentityDetailsDelegatedTeam;
