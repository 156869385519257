import styled from 'styled-components';

const Container = styled.div<{ maxWidth?: string }>`
  max-width: ${props => props.maxWidth || '1100px'};
  margin: 0 auto;
  margin-bottom: 2rem;
  min-height: 400px; // for browser not supporting calc
  min-height: calc(100vh - 120px);
  position: relative;
`;

export default Container;
