import React, {useRef, useState} from 'react';
import ReactResizeDetector from 'react-resize-detector';
import styled from 'styled-components';

const TruncatedTextWrapper = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  max-width: 100%;
`;

type Props = {
  children: React.ReactNode,
  overflows?: (val: boolean) => void
};

/**
 * Displays text and truncates it when it is too long.
 *
 * When truncated the full text is set as "title" attribute, so it is visible on hover.
 */
export function TruncatedText({children, overflows}: Props) {
  const [truncated, setTruncated] = useState(false);
  const contextNode = useRef<HTMLDivElement>(null);

  const onResize = () => {
    if (!contextNode.current) {
      setTruncated(false);
      return;
    }

    if (overflows) {
      const element = contextNode.current;
      overflows(element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth);
    }

    // @see https://stackoverflow.com/questions/7738117/html-text-overflow-ellipsis-detection/10017343#10017343
    // the + 1 is a fix for IE11
    setTruncated(contextNode.current.offsetWidth + 1 < contextNode.current.scrollWidth);
  };

  return (
      <>
        <ReactResizeDetector
            handleWidth
            refreshMode="debounce"
            refreshRate={300}
            onResize={onResize}
        />
        <TruncatedTextWrapper
            title={truncated && contextNode.current && contextNode.current.innerText}
            ref={contextNode}
        >
          {children}
        </TruncatedTextWrapper>
      </>
  );
}
