import React from 'react';

import Panel, { PanelGroup, PanelHeader, PanelSubtitle, PanelTitle } from '@/components/Panel';
import { pushStack } from '@/components/Router/StackController';
import { useDelegatedTeamMembers, useTeamMembers } from '@/hooks/configuredData';
import { t } from '@/i18n';

type Props = {
  uuid: string;
};

function InternalTeamPanel({ uuid }: Props) {
  const { data: teamData } = useTeamMembers(uuid);
  const { data: delegatedTeamData } = useDelegatedTeamMembers(uuid);

  return (
    <PanelGroup>
      <Panel clickable onClick={() => pushStack(`identities/${uuid}/team`)}>
        <PanelHeader decoration="arrowRight">
          <PanelTitle>{t('identity_details.teammembers')}</PanelTitle>
          <PanelSubtitle>
            {teamData.internalMembers &&
              teamData.externalMembers &&
              t('identity_details.teammembers_summary', {
                internalCount: teamData.internalMembers.length,
                externalCount: teamData.externalMembers.length,
              })}
          </PanelSubtitle>
        </PanelHeader>
      </Panel>
      <Panel clickable onClick={() => pushStack(`identities/${uuid}/delegatedTeam`)}>
        <PanelHeader decoration="arrowRight">
          <PanelTitle>{t('identity_details.delegated_teammembers')}</PanelTitle>
          <PanelSubtitle>
            {delegatedTeamData.internalMembers &&
              delegatedTeamData.externalMembers &&
              t('identity_details.delegated_teammembers_summary', {
                internalCount: delegatedTeamData.internalMembers.length,
                //  +
                // (jspath.apply(
                //   config.identity.delegatedTeam.internalMembersHasMore,
                //   internalDelegatedTeam,
                // )[0]
                //   ? '+'
                //   : ''),
                externalCount: delegatedTeamData.externalMembers.length,
                // +
                // (jspath.apply(
                //   config.identity.delegatedTeam.externalMembersHasMore,
                //   externalDelegatedTeam,
                // )[0]
                //   ? '+'
                //   : ''),
              })}
          </PanelSubtitle>
        </PanelHeader>
      </Panel>
    </PanelGroup>
  );
}

export default InternalTeamPanel;
