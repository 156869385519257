import React from 'react';

import DataField from '@/components/DataField';
import {ExternalLinkFieldConfig} from '@/generalization/definitions';

import {FieldProps} from '.';

export default function ExternalLinkField({config, wrapIn}: FieldProps<ExternalLinkFieldConfig>) {
  if (config.value == null || config.value.trim().length === 0) {
    return null;
  }


  let link = config.value;
  for (let i = 0; i < config.variables.length; i++) {
    link = link.replace("$" + (i + 1), config.variables[0])
  }

  const field = (
      <DataField copyText={config.value} label={config.label} description={config.description}>
        <a href={link}>{config.nameValue ? config.nameValue : config.value}</a>
      </DataField>
  );

  if (wrapIn) {
    return wrapIn(field);
  }

  return field;
}
