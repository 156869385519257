export function parseQueryString(query: string) {
  if (!query) {
    return {};
  }

  if (query.startsWith('?')) {
    query = query.slice(1);
  }

  const queryParts = query.split('&');
  const paramMap: any = {};

  queryParts.forEach(queryPart => {
    const keyValue = queryPart.split('=');
    if (keyValue.length === 1) {
      paramMap[decodeURIComponent(keyValue[0])] = true;
    } else {
      paramMap[decodeURIComponent(keyValue[0])] = decodeQueryStringComponent(keyValue[1]);
    }
  });

  return paramMap;
}

export function toQueryString(params: any) {
  return Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');
}

function decodeQueryStringComponent(component: string) {
  return decodeURIComponent(component.replace(/\+/g, '%20'));
}
