// tslint:disable:no-console
import { postError } from './backend';

const HISTORY_SIZE = 200;

enum LogLevel {
  Error = 'error',
  Warn = 'warn',
  Info = 'info',
}

interface LogEntry {
  level: LogLevel;
  timestamp: number;
  message?: string;
  modules?: string;
  error?: {
    message: string;
    name: string;
    stack: string;
  };

  [key: string]: any;
}

const logs: LogEntry[] = [];

function isProd() {
  return process.env.NODE_ENV === 'production';
}

/**
 * Stores a log entry and takes care of history size.
 *
 * @param log the log entry
 */
function storeLogEntry(log: LogEntry) {
  logs.push(log);

  // maintain history size limit
  if (logs.length > HISTORY_SIZE) {
    logs.slice(Math.max(0, logs.length - HISTORY_SIZE));
  }
}

/**
 * Creates a log entry from a list of values
 *
 * A string value will be used as message. An error value will populate the error field of the
 * entry. Object values will be merged as metadata.
 *
 * @param level the log level
 * @param values values for constructing the entry (see method description)
 */
function createLogEntry(level: LogLevel, ...values: any[]): LogEntry {
  let entry: LogEntry = {
    timestamp: Date.now(),
    level,
  };

  values.forEach(value => {
    if (typeof value === 'string') {
      entry.message = value;
    } else if (value instanceof Error) {
      entry.error = {
        message: value.message,
        name: value.name,
        stack: value.stack,
      };
    } else if (typeof value === 'object') {
      entry = { ...entry, ...value };
    }
  });

  return entry;
}

/**
 * Print a log entry to the browsers console. This is no-op in a production build.
 *
 * @param logEntry the log entry
 */
function logToConsole(logEntry: LogEntry) {
  if (isProd()) {
    return;
  }

  const { message, module, timestamp, level, ...meta } = logEntry;

  const titleParts: string[] = [];
  const titleStyles: string[] = [];

  if (module) {
    titleParts.push(`%c[${module}]`);
    titleStyles.push('color: grey; font-weight: normal;');
  }

  if (message) {
    titleParts.push(`%c${message}`);
    titleStyles.push('color: #eb3c96; font-weight: bold;');
  }

  // add timestamp
  titleParts.push(`%c@ ${new Date(timestamp).toLocaleTimeString()}`);
  titleStyles.push('color: grey; font-weight: normal;');

  const logParams: any[] = [`${titleParts.join(' ')}`, ...titleStyles];

  if (Object.keys(meta).length > 0) {
    logParams.push(meta);
  }

  switch (logEntry.level) {
    case LogLevel.Error:
      console.error(...logParams);
      break;

    case LogLevel.Warn:
      console.warn(...logParams);
      break;

    case LogLevel.Info:
      console.info(...logParams);
      break;
  }
}

/**
 * A simple logger which logs messages and meta data objects. It has the ability to construct
 * child loggers and sends error logs to the backend.
 */
export class Logger {
  meta: any = {};

  /**
   * Construct a top level logger with the given meta information.
   *
   * If you want a child logger use Logger.child(…) instead.
   *
   * @example
   * const componentLogger = new Logger({
   *   component: 'my-component'
   * });
   *
   * @param meta the meta information to be added to the logger
   */
  constructor(meta?: any) {
    this.meta = meta;
  }

  /**
   * Create a child logger.
   *
   * @param childMeta additonal metadata for the child logger
   */
  child(childMeta: any) {
    return new Logger({ ...this.meta, ...childMeta });
  }

  /**
   * Log with error level. Error logs are sent to the backend, together with the latest 50 logs.
   *
   * @example
   * logger.error('Something failed.', exception, { requestId: request.id });
   *
   * @param values values for constructing the entry (see createLogEntry(…))
   */
  error(...values: any[]) {
    const logEntry = createLogEntry(LogLevel.Error, this.meta, ...values);
    storeLogEntry(logEntry);
    logToConsole(logEntry);

    // send error to backend and provide the 50 latest logs
    postError({
      ...logEntry,
      latestLogs: logs.slice(Math.max(0, logs.length - 50)),
    });
  }

  /**
   * Log with warn level.
   *
   * @example
   * logger.error('Something weird happened.', exception, { requestId: request.id });
   *
   * @param values values for constructing the entry (see createLogEntry(…))
   */
  warn(...values: any[]) {
    const logEntry = createLogEntry(LogLevel.Warn, this.meta, ...values);
    storeLogEntry(logEntry);
    logToConsole(logEntry);
  }

  /**
   * Log with info level.
   *
   * @example
   * logger.error('Something interesting happened.', exception, { requestId: request.id });
   *
   * @param values values for constructing the entry (see createLogEntry(…))
   */
  info(...values: any[]) {
    const logEntry = createLogEntry(LogLevel.Info, this.meta, ...values);
    storeLogEntry(logEntry);
    logToConsole(logEntry);
  }
}
