import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import styled from 'styled-components';

const FittedTextWrapper = styled.div`
  height: 100%;
  white-space: nowrap;
`;

interface FittedTextProps {
  children: React.ReactText;
  className?: string;
}

interface FittedTextState {
  innerScale: number;
  marginLeft: number;
  marginTop: number;
}

export class FittedText extends React.Component<FittedTextProps, FittedTextState> {
  innerNode: HTMLDivElement;
  wrapperNode: HTMLDivElement;

  constructor(props: FittedTextProps) {
    super(props);

    this.state = {
      innerScale: 1,
      marginTop: 0,
      marginLeft: 0,
    };
  }

  componentDidMount() {
    this.resize();
  }

  componentDidUpdate(prevProps: FittedTextProps, prevState: FittedTextState) {
    if (this.props.children !== prevProps.children) {
      this.resize();
    }
  }

  resize = () => {
    // scale the text to fill the parent container

    if (this.innerNode && this.wrapperNode) {
      const firstScale = (this.wrapperNode.clientWidth / this.innerNode.clientWidth) * 0.6;
      const secondScale = (this.wrapperNode.clientHeight / this.innerNode.clientHeight) * 0.6;

      const calc = {
        innerScale: Math.min(firstScale, secondScale),
        marginLeft: -this.innerNode.clientWidth / 2,
        marginTop: -this.innerNode.clientHeight / 2,
      };

      if (
        calc.innerScale !== this.state.innerScale ||
        calc.marginLeft !== this.state.marginLeft ||
        calc.marginTop !== this.state.marginTop
      ) {
        this.setState(calc);
      }
    }
  };

  render() {
    return (
      <FittedTextWrapper ref={ref => (this.wrapperNode = ref)}>
        <ReactResizeDetector handleHeight handleWidth onResize={this.resize} />
        <div
          ref={ref => (this.innerNode = ref)}
          style={{
            float: 'left',
            transform: `scale(${this.state.innerScale})`,
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: this.state.marginLeft,
            marginTop: this.state.marginTop,
          }}
        >
          {this.props.children}
        </div>
      </FittedTextWrapper>
    );
  }
}
