import {Button} from '@liquid-design/liquid-design-react';
import gql from 'graphql-tag';
import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';

import Container from '@/components/Container';
import {HeaderWithBackButton} from '@/components/HeaderWithBackButton';
import LoadingSpinner from '@/components/LoadingSpinner';
import PersonList from '@/components/PersonList';
import SectionTitle from '@/components/SectionTitle';
import {useQuery} from '@/hooks/graphQl';
import {t} from '@/i18n';

import SearchEntry from './SearchEntry';

interface IIdentitySearchProps {
  searchTerm: string;
  callBack?: (entry: any) => void;
}

const PaginationContainer = styled.div`
  text-align: center;
  margin-bottom: 1rem;
`;

const ExactMatchSection = styled.section`
  margin-bottom: 2rem;
`;

function useSearch(term: string) {
  const page = useRef(1);

  useEffect(() => {
    page.current = 1;
  }, [term]);

  const query = useQuery({
    query: gql(`
      query ($term: String!, $page: Int) {
        search(type: "PERSON", query: $term, size: 20, page: $page) {
          info {
            total
          }

          data {
            ... on PersonSearchResult {
              uuid
              firstName
              lastName
              mlid
              muid
              department
              costCenter
              legalEntity
              exactMatch
            }
          }
        }
      }
    `),
    variables: {
      term,
      page: 1,
    },
  });

  return {
    searchResults: (query.data && query.data.search && query.data.search.data) || [],
    total: (query.data && query.data.search && query.data.search.info.total) || 0,
    loading: query.loading,
    loadMore: () => {
      query.fetchMore({
        variables: {page: page.current + 1},
        updateQuery: (previousQueryResult, {fetchMoreResult}) => {
          return {
            ...previousQueryResult,
            search: {
              ...previousQueryResult.search,
              data: [...previousQueryResult.search.data, ...fetchMoreResult.search.data],
            },
          };
        },
      });
      page.current = page.current + 1;
    },
  };
}

export function IdentitySearchComponent(props: IIdentitySearchProps) {
  const {searchResults, total, loadMore, loading} = useSearch(props.searchTerm);

  const defaultParentRoute = 'identities/me';

  const renderPagination = () => {
    if (!searchResults || searchResults.length === 0) return null;

    return (
        <>
          <PaginationContainer>
            {total > searchResults.length && (
                <Button onClick={loadMore}>{t('common.show_more')}</Button>
            )}
          </PaginationContainer>
          <PaginationContainer>
            {t('common.items_current_of_total', {
              current: searchResults.length,
              total,
            })}
          </PaginationContainer>
        </>
    );
  };

  const renderIdentities = () => {
    if (!searchResults || searchResults.length === 0) return null;

    const exactMatches = searchResults.filter((entry: any) => entry.exactMatch);
    const fuzzyMatches = searchResults.filter((entry: any) => !entry.exactMatch);

    let exactSection = null;
    const hasExactMatch = exactMatches.length > 0;

    if (hasExactMatch) {
      exactSection = (
          <ExactMatchSection>
            <SectionTitle type="H4">{t('identity_search.exact_matches')}</SectionTitle>
            <PersonList>
              {exactMatches.map((entry: any) => (
                  <SearchEntry callBack={props.callBack} key={entry.uuid} entry={entry}/>
              ))}
            </PersonList>
          </ExactMatchSection>
      );
    }

    return (
        <>
          {exactSection}

          {fuzzyMatches.length > 0 && (
              <SectionTitle type="H4">{t('identity_search.similar_matches')}</SectionTitle>
          )}
          <PersonList>
            {fuzzyMatches.map((entry: any) => (
                <SearchEntry callBack={props.callBack} key={entry.uuid} entry={entry}/>
            ))}
          </PersonList>
        </>
    );
  };

  const renderSearchResults = () => {
    return (
        <>
          {renderIdentities()}
          {loading ? <LoadingSpinner inline={true}/> : renderPagination()}
        </>
    );
  };

  return (
      <Container>
        {!props.callBack &&
        <HeaderWithBackButton
            as="H2"
            defaultParentRoute={defaultParentRoute}
            title={t('identity_search.search_results', {searchTerm: props.searchTerm})}
        />
        }
        {renderSearchResults()}
      </Container>
  );
}

export default IdentitySearchComponent;
