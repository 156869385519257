import React, { createContext, useContext } from 'react';
import {useConfig} from "@/hooks/config";
import {useQuery} from "@/hooks/graphQl";
import gql from "graphql-tag";
import {cachedExpression} from "@/utils/queryUtils";

type UserData = {
  fullName: string;
  mlid: string;
  uuid: string;
};

export const UserDataContext = createContext<UserData>(null);
export const useUserData = () => useContext(UserDataContext);

export function UserDataContextProvider({children}: { children: React.ReactNode }) {
  const config = useConfig();

  const userInfoQuery = useQuery({query: gql(config.userInfo._query)});

  const fullNameExpression = cachedExpression(config.userInfo.fullName);
  const fullName = userInfoQuery.data && fullNameExpression.evaluate(userInfoQuery.data);

  const mlidExpression = cachedExpression(config.userInfo.mlid);
  const mlid = userInfoQuery.data && mlidExpression.evaluate(userInfoQuery.data);

  const uuid =
      userInfoQuery.data && cachedExpression(config.userInfo.uuid).evaluate(userInfoQuery.data);

  const userData = {
    fullName,
    mlid,
    uuid,
  };

  if (!fullName || !mlid || !uuid) {
    return null;
  }

  return <UserDataContext.Provider value={userData}>{children}</UserDataContext.Provider>;
}
