import {Icon, Modal} from '@liquid-design/liquid-design-react';
import React from 'react';
import styled from 'styled-components';

import copy from "copy-to-clipboard";
import {TruncatedText} from '@/components/TruncatedText';


const DataFieldWrapper = styled.dl`
  margin-bottom: 1em;
`;

const FieldTitle = styled.dt`
  font-weight: bold;
  margin-bottom: 0.25em;
  font-size: 0.8em;
  color: #888;
`;

const FieldContent = styled.dd`
  font-weight: bold;
`;

const HelpButton = styled.button`
  border-radius: 50%;
  height: 1.25em;
  width: 1.25em;
  font-size: 0.9em;
  border: 0;
  background: ${props => props.theme.colors.sensitiveGrey.darker};
  color: ${props => props.theme.colors.primary.base};
  font-weight: 800;
  margin-left: 0.25em;
  display: inline-block;
  cursor: pointer;
`;

export interface DataFieldProps {
  label: string;
  description?: string;
  children: React.ReactNode;
  truncate?: boolean;
  className?: string;
  copyText?: string;
}

type State = {
  modalOpen: boolean;
  overflows: boolean;
};

class DataField extends React.Component<DataFieldProps, State> {
  static defaultProps = {
    truncate: true,
  };

  inputNode: HTMLInputElement = null;

  state = {
    modalOpen: false,
    overflows: false
  };

  render() {
    const {truncate, label, description, children, className, copyText} = this.props;

    return (
        <DataFieldWrapper className={className}>
          <input ref={r => this.inputNode = r} hidden/>
          <FieldTitle>
            {label} {description && this.descriptionModal()} {copyText && this.state.overflows && this.copyToClipboard()}
          </FieldTitle>
          <FieldContent>
            {truncate ? <TruncatedText overflows={this.setOverflowing}>{children}</TruncatedText> : children}
          </FieldContent>
        </DataFieldWrapper>
    );
  }

  setOverflowing = (overflows: boolean) => {
    this.setState({overflows});
  };

  openModal = () => {
    this.setState({
      modalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
    });
  };

  descriptionModal = () => {
    const {description, label} = this.props;
    const {modalOpen} = this.state;

    return (
        <>
          <HelpButton onClick={this.openModal}>?</HelpButton>
          <Modal label={label} open={modalOpen} onClose={this.closeModal}>
            {description}
          </Modal>
        </>
    );
  };

  copyToClipboard = () => {
    const {copyText} = this.props;
    return (
        <>
          <HelpButton onClick={() => {
            copy(copyText);
          }}><Icon size={11} name="clip"/></HelpButton>
        </>
    );
  };
}

export default DataField;
