import React from 'react';

import Panel from '@/components/Panel';
import WelcomePanel from '@/components/WelcomePanel';
import {t} from '@/i18n';
import {formatInitials} from '@/utils/format';

import {useOverviewData} from '@/hooks/configuredData';
import {useImageLoader} from "@/components/useImageLoader";

type Props = {
    uuid: string;
    isSelf: boolean;
};

function IntroPanel({uuid, isSelf}: Props) {
    const avatarUrl = useImageLoader(uuid, "240x240");
    const {data, query} = useOverviewData(uuid);

    if (query.loading) {
        return null;
    }

    return (
        <Panel>
            <WelcomePanel
                greeting={isSelf && t('identity_details.welcome', {firstName: data.firstName})}
                name={data.fullName}
                email={data.email}
                avatarSrc={avatarUrl}
                initials={formatInitials(data.fullName)}
            />
        </Panel>
    );
}

export default IntroPanel;
