import React, { useState } from 'react';

import { ModalsProvider } from '@/contexts/modals';

export const WELCOME_TEASER_READ = 'giam--welcome-teaser-read';

function ModalsController({ children }: { children?: React.ReactNode }) {
  const [isOpen, setIsOpen] = useState(!localStorage.getItem(WELCOME_TEASER_READ));

  const hideWelcomeModal = () => {
    localStorage.setItem(WELCOME_TEASER_READ, 'read');
    setIsOpen(false);
  };

  const showWelcomeModal = () => {
    setIsOpen(true);
  };

  return (
    <ModalsProvider
      value={{
        welcomeModal: {
          isOpen,
          show: showWelcomeModal,
          hide: hideWelcomeModal,
        },
      }}
    >
      {children}
    </ModalsProvider>
  );
}

export default ModalsController;
