import React from 'react';
import styled from 'styled-components';

export interface ILoadingSpinnerProps {
  hidden?: boolean;
  inline?: boolean;
}

const LoadingSpinnerWrapper = styled.div`
  @keyframes spinner-animation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid ${props => props.theme.colors.secondary.base};
  border-top-color: transparent;
  animation: 1s linear spinner-animation infinite;
`;

function LoadingSpinner({ hidden, inline }: ILoadingSpinnerProps) {
  if (hidden) {
    return null;
  }
  const spinner = <LoadingSpinnerWrapper />;

  if (inline) {
    return <div style={{ position: 'relative', height: '80px' }}>{spinner}</div>;
  }

  return spinner;
}

export default LoadingSpinner;
