import React, {useContext, useEffect, useState} from 'react';
import {InMemoryCache} from "apollo-cache-inmemory";
import {apiUrl, getDefaultHeaders} from "@/backend";
import ApolloClient, {Operation} from "apollo-boost";
import {ApolloProvider} from "react-apollo-hooks";
import {AuthorizationContext} from "@/contexts/authorization";


const APOLLO_CACHE = new InMemoryCache();

export function GraphQLContextProvider({children}: { children: JSX.Element }) {
    const {getAccessToken} = useContext(AuthorizationContext);
    const [apolloClientInstance, setApolloClientInstance] = useState<ApolloClient<InMemoryCache>>(null);

    useEffect(() => {
        const apolloClient = new ApolloClient<InMemoryCache>({
            uri: `${apiUrl}/graphql`,
            cache: APOLLO_CACHE,
            request: async (operation: Operation) => {
                // update access token before request if needed
                const token = await getAccessToken();
                // set auth header etc. for request
                operation.setContext({headers: getDefaultHeaders(token)});
            },

        });
        setApolloClientInstance(apolloClient);
    }, [getAccessToken, setApolloClientInstance]);


    return <ApolloProvider client={apolloClientInstance}>
        {apolloClientInstance && children}
    </ApolloProvider>
}
