import React from 'react';

import DataField from '@/components/DataField';
import {OrgManagerFieldConfig} from '@/generalization/definitions';

import {FieldProps} from '.';

export default function OrgManagerField({config, wrapIn}: FieldProps<OrgManagerFieldConfig>) {
  if (config.value == null || config.value.trim().length === 0) {
    return null;
  }

  // according to the authoritative data source departmentId's less than 1200026 have a leading zero, all others don't.
  const departmentId = config.value < 1200026 ? ("" + config.value).padStart(8, '0') : ("" + config.value);
  const link = "https://orgmanagerreli3ebizf-a257ko61us.eu2.hana.ondemand.com/orgmanager/orghtml/index.html?perspective=EmployeePerspective&rootType=FODepartment&root=" + departmentId + "&highlightType=FODepartment&highlightFieldName=externalCode&highlightObject=" + departmentId;
  // old link maybe used again still here because of other paramters
  // const link = "http://orgman01.merckgroup.com/global_current/index.html?goto=OrgUnit%7C(ObjectID,10," + departmentId + ")";
  const field = (
      <DataField copyText={config.value} label={config.label} description={config.description}>
        <a href={link}>{config.value}</a>
      </DataField>
  );

  if (wrapIn) {
    return wrapIn(field);
  }

  return field;
}
