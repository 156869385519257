import styled from 'styled-components';

export const FieldGrid = styled.div`
  display: flex;
  margin: 1em -1em 2em 0;
  flex-wrap: wrap;
`;

export const FieldColumn = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
  padding-right: 1em;

  @media (max-width: 860px) {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }

  @media (max-width: 650px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (max-width: 540px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
