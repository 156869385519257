import React from 'react';

import DataField from '@/components/DataField';
import {PhoneFieldConfig} from '@/generalization/definitions';

import {FieldProps} from '.';

export default function PhoneField({config, wrapIn}: FieldProps<PhoneFieldConfig>) {
  if (config.value == null || config.value.trim().length === 0) {
    return null;
  }

  const field = (
      <DataField copyText={config.value} label={config.label} description={config.description}>
        <a href={`tel:${config.value}`}>{config.value}</a>
      </DataField>
  );

  if (wrapIn) {
    return wrapIn(field);
  }

  return field;
}
