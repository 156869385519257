import React from 'react';

import Container from '@/components/Container';
import DynamicPanel from '@/components/DynamicPanel';
import { HeaderWithBackButton } from '@/components/HeaderWithBackButton';
import { useUserData } from '@/contexts/userData';
import { useContractData, usePersonalInformationData } from '@/hooks/configuredData';
import { t } from '@/i18n';
import { flatten } from '@/utils/flatten';

interface IIdentityDetailsDataProps {
  params: any;
  overview?: boolean;
}

function IdentityDetailsDataComponent({ overview = false, params }: IIdentityDetailsDataProps) {
  const userData = useUserData();

  const identityUuid = () => {
    return params.uuid === 'me' ? userData.uuid : params.uuid;
  };

  const { data, query } = useContractData(identityUuid());
  const { data: personalData, query: personalDataQuery } = usePersonalInformationData(
    identityUuid(),
  );

  const dataPanels = flatten(data.panels);

  return (
    <Container>
      <HeaderWithBackButton
        as="H2"
        // Hide title until owner name is loaded
        title={
          personalData.owner &&
          t('identity_details_data.personal_information_of', { owner: personalData.owner })
        }
        defaultParentRoute={overview ? 'identities/me' : `identities/${params.uuid}`}
      />

      {!personalDataQuery.loading && personalData.panel && (
        <DynamicPanel config={personalData.panel} />
      )}

      {!query.loading &&
        (dataPanels &&
          dataPanels.map((panelEntry: any, i: number) => (
            <DynamicPanel key={i} config={panelEntry} />
          )))}
    </Container>
  );
}

export default IdentityDetailsDataComponent;
