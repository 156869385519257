import React from 'react';

import DataField from '@/components/DataField';
import {StringFieldConfig} from '@/generalization/definitions';

import {FieldProps} from '.';

export default function StringField({config, wrapIn}: FieldProps<StringFieldConfig>) {
  if (config.value == null || config.value.trim().length === 0) {
    return null;
  }

  const field = (
      <DataField copyText={config.value} label={config.label} description={config.description}>
        {config.value}
      </DataField>
  );

  if (wrapIn) {
    return wrapIn(field);
  }

  return field;
}
