import styled, { css } from 'styled-components';

/**
 * Displays a circle of specified color, intended to express a status.
 *
 * Is also able to flash (aka. blink).
 */
const TrafficLight = styled.div<{ color: string; flashing?: boolean; block?: boolean }>`
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin-left: 2px;
  margin-right: 0.6em;
  position: relative;
  top: 2px;
  background: ${props => props.color};

  ${props =>
    props.block &&
    `
    display: block;
    margin: 0;
    top: 0;
  `}

  @keyframes giam--traffic-light-flashing-animation {
    0%,
    10%,
    90%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }
  }

  ${props =>
    props.flashing &&
    css`
      animation: giam--traffic-light-flashing-animation 1.5s infinite;
    `}
`;

export default TrafficLight;
