import React from 'react';

import {
  CollapsiblePanel,
  PanelHeader,
  PanelStatus,
  PanelSubtitle,
  PanelTitle,
} from '@/components/Panel';

import { DefaultPanelConfig } from '@/generalization/definitions';

import { PanelProps } from '.';
import { FieldColumn, FieldGrid } from '../FieldGrid';
import TrafficLight from '../TrafficLight';
import Field from './Field';

type Props = PanelProps<DefaultPanelConfig>;

const panelColorMapping = (key: string): string => {
  switch (key.toLocaleLowerCase()) {
    case 'green':
      return '#b0d61a';
    case 'red':
      return '#F34747';
    case 'grey':
      return '#DCDCDC';
    case 'yellow':
      return '#ffde3a';
    default:
      return null;
  }
};

function DefaultPanel({ config, defaultExpanded, ...props }: Props) {
  return (
    <CollapsiblePanel
      defaultExpanded={defaultExpanded}
      header={
        <PanelHeader withStatus={!!config.status} clickable={true}>
          {config.status && (
            <PanelStatus>
              <TrafficLight color={panelColorMapping(config.status)} block />
            </PanelStatus>
          )}
          <PanelTitle>{config.title}</PanelTitle>
          <PanelSubtitle>{config.subtitle}</PanelSubtitle>
        </PanelHeader>
      }
      {...props}
    >
      <FieldGrid>
        {config.fields.map((field, index) => (
          <Field wrapIn={f => <FieldColumn>{f}</FieldColumn>} key={index} config={field} />
        ))}
      </FieldGrid>
    </CollapsiblePanel>
  );
}

export default DefaultPanel;
