import React from 'react';
import styled, { css } from 'styled-components';

import { Avatar } from './Avatar';
import Panel from './Panel';

const PersonList = styled.ul`
  margin-bottom: 2rem;
  list-style: none;
`;

const PersonItemWrapper = styled(Panel)<{ clickable?: boolean }>`
  &:not(:last-child) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid ${props => props.theme.colors.sensitiveGrey.darker};
  }

  &:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  && {
    margin-bottom: 0;
  }

  display: flex;

  ${props =>
    props.clickable &&
    css`
      cursor: pointer;
    `}
`;

const PersonDetails = styled.div`
  overflow: hidden;
`;

const PersonTitle = styled.div`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PersonSubtitle = styled.div`
  font-size: 0.9em;
  color: #777;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PersonSubtitleItem = styled.span`
  &:not(:last-child):after {
    content: '·';
    margin: 0 0.5em;
  }
`;

const PersonAvatar = styled(Avatar)`
  margin: -0.25rem 1rem -0.25rem 0;
`;

type Props = {
  title: string;
  items?: string[];
  imageSrc?: string;
  initials?: string;
  selected?:boolean;
  onClick?: (e:any) => void;
};

export function PersonItem({ title, items = [], imageSrc, initials, onClick, ...props }: Props) {
  return (
    <PersonItemWrapper {...props} onClick={onClick} clickable={!!onClick}>
      <PersonAvatar size="3rem" src={imageSrc} initials={initials} />
      <PersonDetails>
        <PersonTitle>{title}</PersonTitle>
        <PersonSubtitle>
          {items.map((item, i) => (
            <PersonSubtitleItem key={i}>{item}</PersonSubtitleItem>
          ))}
        </PersonSubtitle>
      </PersonDetails>
    </PersonItemWrapper>
  );
}

export default PersonList;
