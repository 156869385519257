import styled, { css } from 'styled-components';

const NoEntries = styled.div<{ absolute?: boolean }>`
  font-weight: bold;
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
  margin: 0.5em 0 1em 0;

  &:first-child {
    margin-top: 0;
  }

  ${props =>
    props.absolute &&
    css`
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin-top: -10px;
    `}
`;

export default NoEntries;
