export function flatten(nested: any[][]) {
  if (nested == null) return nested; // handle null and undefined

  const result: any[] = [];

  nested.forEach((panel: any) =>
    panel.forEach((panelEntry: any, i: number) => {
      result.push(panelEntry);
    }),
  );

  return result;
}
