import React, {ReactNode} from "react";

export interface AppConfiguration {
  clientId: string;
  tenantId: string;
  appUri: string;
}

export const EMPTY_CONFIGURATION: AppConfiguration = {
  clientId: "",
  tenantId: "",
  appUri: "",
}

export const AppConfigurationContext = React.createContext<AppConfiguration>(EMPTY_CONFIGURATION);

export const AppConfigurationContextProvider = ({configuration, children}: {
  configuration: AppConfiguration,
  children: ReactNode
}) => {
  return <AppConfigurationContext.Provider value={configuration}>
    {configuration && <>{children}</>}
    </AppConfigurationContext.Provider>
}
