import React from 'react';

import NoEntries from '@/components/NoEntries';
import Panel, { PanelBody, PanelHeader, PanelTitle } from '@/components/Panel';
import { t } from '@/i18n';

function TeamProcessesPanel() {

  return (
    <Panel>
      <PanelHeader>
        <PanelTitle>{t('identity_details.team_processes')}</PanelTitle>
      </PanelHeader>
      <PanelBody>
        <NoEntries>{t('identity_details.no_active_processes')}</NoEntries>
      </PanelBody>
    </Panel>
  );
}

export default TeamProcessesPanel;
