import React from 'react';
import {pushStack} from '@/components/Router/StackController';

import DataField from '@/components/DataField';
import {ClickablePersonFieldConfig} from '@/generalization/definitions';

import {FieldProps} from '.';

export default function ClickablePersonField({
                                               config,
                                               wrapIn,
                                             }: FieldProps<ClickablePersonFieldConfig>) {
  if (config.nameValue == null || config.identityUuid == null) {
    return null;
  }

  // TODO: add correct href
  const field = (
      <DataField copyText={config.nameValue} label={config.label}>
        <a
            href="#"
            onClick={e => {
              e.preventDefault();
              pushStack(`identities/${encodeURIComponent(config.identityUuid)}`);
            }}
        >
          {' '}
          {config.nameValue}
        </a>
      </DataField>
  );

  if (wrapIn) return wrapIn(field);

  return field;
}
