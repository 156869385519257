import React from 'react';

import {extractRouteParams, findMatchingRouteIdentifier} from './routeParams';
import {parseQueryString} from '@/utils/queryString';

export type RouteChangeHandler = (path: string, state: any) => void;

export interface IRouterController {
  addRouteChangeHandler: (handler: RouteChangeHandler) => void;
  pushRoute: (path: string, state?: any, skipNotify?: boolean) => void;
  replaceRoute: (path: string, state?: any) => void;
  getCurrentPath: () => string;
}

type RouterMiddleware = (controller: IRouterController) => void;
type RouteHandler = RouterMiddleware | React.ClassType<any, any, any>;

export interface IRoute {
  path: string;
  handle: RouteHandler[];
}

interface IRouterState {
  component: React.ReactType;
  params: any;
}

interface IRouterProps {
  routes: IRoute[];
  controller: IRouterController;
}

export class Router extends React.Component<IRouterProps, IRouterState> {
  constructor(props: IRouterProps) {
    super(props);

    this.state = {
      component: null,
      params: {},
    };
  }

  componentDidMount() {
    this.props.controller.addRouteChangeHandler(this.route);
    this.route(this.props.controller.getCurrentPath());
  }

  handle = (currentPath: string, routePath: string, fns: RouteHandler[], additionalParams: any) => {
    const [currentHandler, ...stack] = fns;

    if (currentHandler) {
      const component = currentHandler(this.props.controller, () =>
          this.handle(currentPath, routePath, stack, additionalParams),
      );

      let par = extractRouteParams(routePath, currentPath);
      par = {__additionalParams: additionalParams, ...par}

      if (component) {
        this.setState({
          component,
          params: par,
        });
      }
    }
  };

  route = (path: string) => {
    // remove GET params
    const urlSplitted = path.split('?');
    const strippedPath = urlSplitted[0];

    const newRoute = this.getCurrentRoute(strippedPath);

    if (!newRoute) {
      return;
    }

    let additionalParams = {};
    if (urlSplitted.length > 1) {
      additionalParams = this.getJsonFromUrl(urlSplitted[1]);
    }

    // custom handler
    if (newRoute.handle) {
      this.handle(strippedPath, newRoute.path, newRoute.handle, additionalParams);
      return;
    }
  };

  render() {
    const Component = this.state.component;

    const {controller, routes, ...additionalProps} = this.props;

    return Component ? (
        <Component
            router={controller}
            params={this.state.params}
            query={parseQueryString(window.location.search)}
            {...additionalProps}
        />
    ) : null;
  }

  getCurrentRoute = (path: string): IRoute => {
    const {routes} = this.props;

    // look for matching route
    return findMatchingRouteIdentifier(path, routes);
  };

  getJsonFromUrl = (url: string) => {
    const result: any = {};
    url.split("&").forEach((part) => {
      const item = part.split("=");
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

}
