import { IRoute } from './Router';

export const extractRouteParams = (routeIdentifier: string, currentHash: string) => {
  const splittedHash = currentHash.split('/');
  const splittedRouteIdentifier = routeIdentifier.split('/');

  return splittedRouteIdentifier
    .map((routeIdentifierToken, index) => {
      if (routeIdentifierToken.indexOf(':', 0) === -1) {
        return null;
      }
      const routeParam: any = {};
      const key = routeIdentifierToken.substr(1, routeIdentifierToken.length - 1);
      routeParam[key] = splittedHash[index];
      return routeParam;
    })
    .filter(p => p !== null)
    .reduce((acc, curr) => {
      Object.keys(curr).forEach(key => {
        acc[key] = decodeURIComponent(curr[key]);
      });
      return acc;
    }, {});
};

export const findMatchingRouteIdentifier = (currentHash: string, routes: IRoute[]) => {
  const splittedHash = currentHash.split('/');
  const firstHashToken = splittedHash[0];

  return routes.filter(route => {
    // any matcher
    if (route.path === '**') {
      return true;
    }

    let splittedRouteKey = route.path.split('/');

    // handle trailing wildcards
    const hasTrailingWildcard = route.path.endsWith('/**');

    if (hasTrailingWildcard) {
      // ignore wildcard token and all following tokens
      splittedRouteKey = splittedRouteKey.slice(0, splittedRouteKey.length - 1);
    }

    const staticRouteTokensAreEqual =
      splittedRouteKey
        .map((routeToken, i) => {
          if (routeToken.indexOf(':', 0) !== -1) {
            return true;
          }
          return routeToken === splittedHash[i];
        })
        .reduce((countInvalid, currentValidationState) => {
          if (currentValidationState === false) {
            ++countInvalid;
          }
          return countInvalid;
        }, 0) === 0;

    return (
      route.path.indexOf(firstHashToken, 0) !== -1 &&
      staticRouteTokensAreEqual &&
      (splittedHash.length === splittedRouteKey.length || hasTrailingWildcard)
    );
  })[0];
};
