import React from 'react';
import styled from 'styled-components';

export interface IBackButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const BackButtonWrapper = styled.button`
  position: relative;
  padding: 0;
  height: 16px;
  background: transparent;
  border: 0;
  margin-bottom: 1rem;
  cursor: pointer;
  font-size: 0.8rem;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  svg {
    height: 14px;
    position: relative;
    top: 1px;
    fill: ${props => props.theme.colors.primary.base};
  }
`;

const TitleWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  font-weight: 600;
  color: #503291;
  margin-left: 8px;
`;

function BackButton({onClick}: IBackButtonProps) {
  return (
      <BackButtonWrapper onClick={onClick}>
        <svg
            viewBox="0 0 22 19"
            height="19"
            width="22"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <path d="M11.663,2.163l-2.163,-2.163l-9.5,9.5l9.5,9.5l2.163,-2.163l-7.337,-7.337l7.337,-7.337Z"/>
          <rect x="2.56" y="7.977" width="19.44" height="3.047"/>
        </svg>
        <TitleWrapper>Back</TitleWrapper>
      </BackButtonWrapper>
  );
}

export default BackButton;
