import jsonata from 'jsonata';

const cache: { [key: string]: jsonata.Expression } = {};

export function cachedExpression(exp: string) {
  if (!cache[exp]) {
    cache[exp] = jsonata(exp);
  }

  return cache[exp];
}

export function mapResult(data: any, config: any, ctx: any = {}): any {
  if (typeof config === 'string') {
    return cachedExpression(config).evaluate(data, ctx);
  }

  if (config instanceof Array) {
    return config.map(configEntry => mapResult(data, configEntry, ctx));
  }

  const { $item, ...configWithoutBase } = config;
  if ($item) {
    let items = mapResult(data, $item, ctx) || [];
    if (!(items instanceof Array)) {
      // make single result also an array
      items = [items];
    }
    return items.map((item: any) => mapResult(data, configWithoutBase, { ...ctx, item }));
  }

  const result: any = {};

  Object.keys(config)
    .filter(key => !key.startsWith('$'))
    .forEach(key => {
      if (key.startsWith('_')) {
        // keys starting with underscore have raw values
        result[key.substr(1)] = config[key];
      } else {
        result[key] = mapResult(data, config[key], ctx);
      }
    });

  return result;
}
