import {IRouterController, RouteChangeHandler} from './Router';

/**
 * The base path of the application.
 */
const basePath: string = "";

/**
 * Callback for route changes.
 */
const routeChangeHandlers: RouteChangeHandler[] = [];

/**
 * Push a route to the history.
 * @param path The path of the new route.
 */
function pushRoute(path: string, state: any = {}, skipNotify: boolean) {
  window.history.pushState(state, '', withBasePath(path));

  // scroll to top, like user is going to a new page
  window.scroll({top: 0});

  if (!skipNotify) {
    notifyRouteChangeHandlers(path, state);
  }
}

/**
 * Replace the current route in the history.
 * @param path The path of the new route.
 */
function replaceRoute(path: string, state: any = {}) {
  window.history.replaceState(state, '', withBasePath(path));
  notifyRouteChangeHandlers(path, state);
}

function notifyRouteChangeHandlers(path: string, state: any) {
  routeChangeHandlers.forEach(handler => handler(path, state));
}

/**
 * Get the path of the current route.
 */
function getCurrentPath() {
  return withoutBasePath(window.location.pathname + window.location.search);
}

/**
 * Set the route change callback.
 * @param handler The callback, receiving the path of new routes.
 */
function addRouteChangeHandler(handler: RouteChangeHandler) {
  routeChangeHandlers.push(handler);
}

/**
 * Strip the base path from a full path and remove trailing slashes.
 *
 * e.g. '/path/to/app/current/route/' -> 'current/route'
 * @param fullPath The full path relative to the webpage root with leading slash.
 */
function withoutBasePath(fullPath: string) {
  let relative = fullPath.slice(basePath.length, fullPath.length);

  if (relative.startsWith('/')) {
    relative = relative.slice(1);
  }

  if (relative.endsWith('/')) {
    relative = relative.slice(0, relative.length - 1);
  }

  return relative;
}

function withBasePath(path: string) {
  if (!path) {
    return basePath;
  }

  return (basePath.endsWith('/') ? basePath : basePath + '/') + path;
}

// register the route change handler to popstate event
window.addEventListener('popstate', event => {
  notifyRouteChangeHandlers(getCurrentPath(), event.state);
});

export const HistoryRouterController: IRouterController = {
  addRouteChangeHandler,
  pushRoute,
  replaceRoute,
  getCurrentPath,
};
