import React from 'react';

import {FieldConfig} from '@/generalization/definitions';

import AddressField from './AddressField';
import BooleanField from './BooleanField';
import ClickablePersonField from './ClickablePersonField';
import DateField from './DateField';
import EmailField from './EmailField';
import ExternalLinkField from './ExternalLinkField';
import MultilineField from './MultilineField';
import OrgManagerField from "./OrgManagerField";
import PhoneField from './PhoneField';
import StringField from './StringField';

export interface FieldProps<Config = FieldConfig> {
  config: Config;
  wrapIn?: (field: React.ReactElement<any>) => React.ReactElement<any>;
}

export default function Field(props: FieldProps) {
  const RealField = fieldMap[props.config.type];

  if (RealField) {
    return <RealField {...props} />;
  }

  return null;
}

// ===== Add new fields here! =====
const fieldMap: { [key: string]: React.ReactType<FieldProps> } = {
  string: StringField,
  multiline: MultilineField,
  address: AddressField,
  date: DateField,
  boolean: BooleanField,
  phone: PhoneField,
  email: EmailField,
  clickablePerson: ClickablePersonField,
  externalLink: ExternalLinkField,
  orgManager: OrgManagerField
};
