import React from 'react';

import { t } from '@/i18n';

import ErrorPage from './ErrorPage';

export default function ErrorPage401() {
  return (
    <ErrorPage title={t('error_pages.401.unauthorized')}>
      <p
        dangerouslySetInnerHTML={{
          __html: t('error_pages.401.HTML_ms_account_hint'),
        }}
      />
    </ErrorPage>
  );
}
