import React from 'react';
import styled from 'styled-components';

import Container from '@/components/Container';
import { HeaderWithBackButton } from '@/components/HeaderWithBackButton';
import { PanelGroup } from '@/components/Panel';
import { useUserData } from '@/contexts/userData';
import { useOverviewData } from '@/hooks/configuredData';
import { t } from '@/i18n';

import DelegatedProcessesPanel from './panels/DelegatedProcessesPanel';
import DetailsPanel from './panels/DetailsPanel';
import IntroPanel from './panels/IntroPanel';
import ProcessesPanel from './panels/ProcessesPanel';
import TeamPanel from './panels/TeamPanel';
import TeamProcessesPanel from './panels/TeamProcessesPanel';
import { replaceStack } from '@/components/Router/StackController';

const Section = styled.div<{ ratio?: number }>`
  flex: ${props => props.ratio || 0} 1 0%;
  flex-wrap: wrap;

  @media (max-width: 900px) {
    flex-basis: 100%;
  }

  margin: 0 1rem 2rem;
  overflow: hidden;
`;

const SplitLayout = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin: 0 -1rem;
`;

interface IIdentityDetailsProps {
  uuid: string;
}

export function IdentityDetailsComponent({ uuid }: IIdentityDetailsProps) {
  const userData = useUserData();

  const identityUuid = () => {
    return uuid === 'me' ? userData.uuid : uuid;
  };

  const defaultParentRoute = () => {
    return isSelf() ? null : '';
  };

  const isSelf = () => {
    return uuid === 'me';
  };

  const { data, query } = useOverviewData(identityUuid());

  if (query.loading) {
    return null;
  }

  if (!data.hasPermission) {
    // user does not have permissions for this site, redirect to overview aka 'scout niveau'
    replaceStack(`identities/${uuid}/overview`);
    return null;
  }

  return (
    <Container maxWidth="1200px">
      <HeaderWithBackButton
        as="H2"
        title={isSelf() ? t('identity_details.my_view') : data.fullName}
        defaultParentRoute={defaultParentRoute()}
      />

      <SplitLayout>
        <Section ratio={38}>
          <IntroPanel isSelf={isSelf()} uuid={identityUuid()} />
          <DetailsPanel uuid={uuid} />
        </Section>

        <Section ratio={62}>
          <PanelGroup>
            <ProcessesPanel/>
            <TeamProcessesPanel/>
            <DelegatedProcessesPanel/>
          </PanelGroup>
          <PanelGroup>
            <TeamPanel uuid={identityUuid()} />
          </PanelGroup>
        </Section>
      </SplitLayout>
    </Container>
  );
}

export default IdentityDetailsComponent;
