import React from 'react';

import { PanelConfig } from '@/generalization/definitions';

import DefaultPanel from './DefaultPanel';
import PanelWithImage from './PanelWithImage';

export interface PanelProps<Config = PanelConfig> {
  config: Config;
  defaultExpanded?: boolean;
}

export type MapValue<Config = PanelConfig> = React.ComponentType<PanelProps<Config>>;

export default function DynamicPanel({ config, defaultExpanded, ...props }: PanelProps) {
  const RealPanel = panelMap[config.type];

  if (RealPanel) {
    return <RealPanel config={config} defaultExpanded={defaultExpanded} {...props} />;
  }

  return null;
}

// ===== Add new panels here! =====
const panelMap: { [key: string]: MapValue } = {
  with_image: PanelWithImage,
  default: DefaultPanel,
};
