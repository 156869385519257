import React from 'react';

import {PersonItem} from '@/components/PersonList';
import {pushStack} from '@/components/Router/StackController';
import {t} from '@/i18n';
import {formatInitials} from '@/utils/format';
import {useImageLoader} from "@/components/useImageLoader";

function SearchEntry({entry, callBack}: { entry: any, callBack?: (entry: any) => void }) {
    const subtitleItems: string[] = [];

    if (entry.department && entry.department.length > 0) {
        subtitleItems.push(entry.department.join(', '));
    }

    if (entry.legalEntity && entry.legalEntity.length > 0) {
        subtitleItems.push(
            t('identity_search.subtitle.subsidiary', {
                subsidiary: entry.legalEntity.join(', '),
            }),
        );
    }

    if (entry.costCenter) {
        subtitleItems.push(t('identity_search.subtitle.costCenter', {costCenter: entry.costCenter}));
    }

    if (entry.muid) {
        subtitleItems.push(t('identity_search.subtitle.muid', {muid: entry.muid}));
    }

    const avatarUrl = useImageLoader(entry.uuid, "120x120");
    return (
        <PersonItem
            title={`${entry.firstName} ${entry.lastName}`}
            imageSrc={avatarUrl}
            initials={formatInitials(entry.firstName, entry.lastName)}
            items={subtitleItems}
            onClick={() => {
                if (callBack) {
                    callBack(entry);
                } else {
                    pushStack(`identities/${encodeURIComponent(entry.uuid)}`);
                }
            }}
        />
    );
}

export default SearchEntry;
