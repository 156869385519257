/**
 * Provide context! The higher level keys are for grouping and providing context, the last part of the key should
 * reflect the english translation. The last part of the key is used as fallback, together with the rest of the page it
 * should be enough to use the application.
 *
 * @example
 * {
 *   identity_details: {
 *     view_all_processes: 'View all',
 *   },
 * },
 */
export default {
  common: {
    app_name: 'Loom',
    network_error: 'Network Error',
    show_more: 'Show more',
    items_current_of_total: '{current} of {total}',
  },

  data_loader: {
    retry: 'Retry',
    error: 'An error occured. Please try to load this Page again.',
    contact_it_support:
        "If the error still occures contact the IT-Support and provide the code '{id}'.",
  },

  error_pages: {
    401: {
      unauthorized: 'Unauthorized',
      HTML_ms_account_hint:
          'Please log out of your <a href="https://www.office.com/">Microsoft Account</a> and try again.',
    },
  },

  navbar: {
    my_view: 'Loom',
    my_personal_information: 'Personal Information',
    my_equipment: 'Equipment',
    my_accounts_and_authorizations: 'Accounts & Authorizations',
    my_accountabilities: 'Accountabilities',
    my_user_tasks: 'User Tasks',
    faq_loom_eva: 'Loom EVA FAQ',
    credential_portal: 'Credential Portal',
    entitlement_portal: 'Entitlement Portal',
    feedback: 'Feedback',
    logout: 'Logout',
  },

  identity_details: {
    my_view: 'My View',
    show_all: 'Show All',

    my_team: 'My Team',
    team_of: '{name} | Team',

    my_delegated_team: 'My Delegated Team',
    delegated_team_of: '{name} | Delegated Team',

    welcome: 'Hello {firstName}, welcome to Loom!',

    process_automated: 'Automated by GIAM',
    process_not_automated: 'Not Automated by GIAM',

    my_processes: 'My Processes',
    team_processes: 'Team Processes',
    load_more_processses: 'Load more',
    show_finished_processes: 'Show finished proceses ({count})',
    delegated_team_processes: 'Delegated Team Processes',
    process_fields: {
      start_date: 'Start Date: {date}',
    },

    no_active_processes: 'There are no active processes.',
    no_finished_processes: 'There are no finished processes.',
    view_all_processes: 'View All',

    details: 'Details',

    personal_information: 'Personal Information',
    equipment: 'Equipment',
    accounts_and_authorizations: 'Accounts & Authorizations',
    accountabilities: 'Accountabilities',

    teammembers: 'Team Members',
    teammembers_summary:
        '{internalCount} Internal Team Members, {externalCount} External Team Members',
    internal_teammembers: 'Internal Teammembers',
    external_teammembers: 'External Teammembers',

    delegated_teammembers: 'Delegated Team Members',
    delegated_teammembers_summary:
        '{internalCount} Internal Team Members, {externalCount} External Team Members',
    processes_in_your_team: 'Processes In Your Team',
    processes_coming_soon: 'Coming Soon…',
    basic_login_accounts: 'Basic Login Accounts',
    admin_login_accounts: 'Admin Login Accounts',
    google_suite_account: 'Google Suite Account',
    collaboration: 'Collaboration',
    application_access: 'Application Access (Active Directory)',
    adlds_application_access: 'Adlds Application Access (Active Directory)',
    server_admin_access: 'Server Admin Access (Active Directory)',
    sap_accounts: 'SAP Accounts',
    physical_access: 'Physical Access',
    employee: 'Employee',
    contact: 'Telephone, E-Mail',
    company: 'Company',
    cost_centers: 'Cost Centers',
    service_account: 'Service Accounts (CDM)',
    resource_accounts: 'Resource Accounts (CDM)',
    load_more_user_tasks: 'Load more',
    snow_access: 'Service NOW Access (SNOW)',
    O356mail_delegate_access: 'O365Mail Delegate Access',
    telco_account: 'Telco Account'
  },

  user_task: {
    user_tasks: 'User Tasks',
    decision_backend_error: 'An server error occured. Please retry in a few minutes.',
    load_next_page: 'Next Page',
    load_prev_page: 'Prev Page',
    task_detail_panel_error_header: 'Error on requested UserTask',
    no_tasks_available: 'No user tasks found, try to remove/change filters',
    successful_update_info: 'user task successfully updated',
    filters: {
      filter_title: 'Filters',
      label_search_person: 'search for person',
      label_search_process: 'process ID',
      error_not_valid_uuid: 'not a valid UUID'
    }
  },

  identity_details_data: {
    personal_information_of: '{owner} | Personal Information',
    no_accounts_and_authorizations: 'No accounts or authorizations.',
  },

  identity_details_accounts: {
    accounts_and_authorizations_of: '{owner} | Accounts and Authorizations',
  },

  identity_details_equipment: {
    equipment_of: '{owner} | Equipment',

    mobile_devices: 'Mobile Devices',
    self_used_devices: 'My Devices',
    used_by_others: 'Other Devices Accountable for',
    kace_devices: 'Kace Devices',
    jamf_devices: 'Jamf Devices',
    self_sim_card: 'My SIM Cards',
    used_by_others_sim_card: 'Other SIM Cards Accountable for',
    no_equipment: 'No equipment.',
    resource_devices: 'Resource Devices',
    sccm_devices: 'SCCM Devices',
  },

  identity_details_accountabilities: {
    accountabilities_of: '{owner} | Accountabilities',
    resource_devices: 'Resource Devices',
  },

  identity_details_processes: {
    processes: 'Processes',
    deadline: 'Joining Date: {date}',
    no_running_processes: 'No running Processes.',
  },

  identity_search: {
    exact_matches: 'Exact Matches',
    similar_matches: 'Similar Matches',
    search_results: 'Search Results for "{searchTerm}"',
    subtitle: {
      costCenter: 'Cost-Center: {costCenter}',
      subsidiary: 'Subsidiary: {subsidiary}',
      muid: 'MUID: {muid}',
    },
  },

  personal_information: {
    InternalEmploymentContract: 'Internal Employee',
    ExternalEmploymentContract: 'External Employee',
    leaver: 'leaver',
  },

  welcome_modal: {
    learn_more: 'Learn more »',
    previous_slide: '« Previous',
  },

  process_details: {
    step_status: {
      active: 'Active',
      pending: 'Pending',
      completed: 'Completed',
      failed: 'Failed',
    },

    view_subprocess: 'View Subprocess',

    process_status: 'Process Status',
    details: 'Details',
    deadline: 'Joining Date',

    headlines: {
      steps: 'Steps',
      origin: 'Origin',
      what_is_there: 'What is there?',
      equipment: 'Equipment',
      accounts: 'Accounts',
      target_state: 'Technical Checklist: IT Account Target State {version}',
      target_state_subtitle: 'Information for IT Support only',
    },

    no_checks: 'No checks.',

    no_equipment: 'No equipment.',
    no_accounts_and_authorizations: 'No accounts or authorizations.',

    status: {
      impediment: 'Impediment',
      finished: 'Finished',
      delayed: 'Delayed',
      running: 'Running',
    },

    business_processes: 'Business Processes',
    no_mandatory_fulfillments: 'No mandatory fulfillments.',
    no_open_snow_tickets: 'No open service requests.',
    no_information_available: 'No information available.',
    no_business_processes: 'No business processes.',
    due: 'Due date: {date}',
  },

  login: {
    username: 'Username',
    password: 'Password',
    login: 'Login',

    provider_items: {
      dummy_provider: 'Dummy Provider',
      login_using: 'Login using {ipName}',
    },
  },

  fields: {
    boolean_field: {
      yes: 'Yes',
      no: 'No',
    },

    multiline_field: {
      show_all_items: 'Show all ({count})',
    },
  },

  person_search_modal: {
    description_text: 'search for person: ',
    input_text_label: 'type in muid or name'
  }
};
