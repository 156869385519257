import {useCallback, useState} from "react";

import {LoadingContext} from "@/contexts/LoadingContext";

export const useLoading = (): LoadingContext => {
  const [loading, setInternloading] = useState(false);
  const setLoading = useCallback((currentLoading: boolean): void => {
    setInternloading(currentLoading);
  }, []);
  return {loading, setLoading};
};
