let i18nMap: any = {};

export function initI18n(translationMap: any) {
  i18nMap = translationMap;
}

/**
 * Get a translated string.
 * @param key the translation key
 * @param values key-value map for placeholder values
 */
export function t(key: string, values?: { [key: string]: any }) {
  const keyPath = key.split('.');
  const translation = keyPath.reduce((acc, val) => acc && acc[val], i18nMap) as string;

  if (translation === undefined) {
    const fallback = keyPath[keyPath.length - 1];

    // when not in production -> print error message and mark fallback
    if (process.env.NODE_ENV !== 'production') {
      // tslint:disable-next-line:no-console
      console.error(`[i18n] Missing entry: ${key}`);
      return `??${fallback}??`;
    }

    return fallback;
  }

  if (!values) {
    return translation;
  }

  const valueKeys = Object.keys(values);

  return valueKeys.reduce((translated, valueKey) => {
    const value = values[valueKey];
    return translated.replace(`{${valueKey}}`, value);
  }, translation);
}
