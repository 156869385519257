import {Glyph} from '@liquid-design/liquid-design-react';
import React, {useState} from 'react';
import {withContentRect} from 'react-measure';
import styled, {css} from 'styled-components';

const Panel = styled.div<{ clickable?: boolean, selected?: boolean, children?: React.ReactNode }>`
  background: ${props => props.selected ? "#D3D3D3" : "#fff"};
  border-radius: ${props => props.theme.borderRadius};
  padding: 24px;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  ${props =>
    props.clickable &&
    css`
      cursor: pointer;
    `}
`;

export const PanelGroup = styled.div`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  ${Panel} {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`;

export const PanelBody = styled.div`
  padding-top: 1rem;
`;

type CollapsiblePanelBodyProps = {
  collapsed?: boolean;
};

const CollapsiblePanelBodyWrapper = styled.div<CollapsiblePanelBodyProps & { contentRect: any }>`
  transition: height 0.3s ease-out;
  @keyframes panel_wrapper__delayedShow {
    to {
      overflow: visible;
    }
  }
  ${({collapsed, contentRect}) => {
  return collapsed
      ? css`
          height: 0px;
          overflow: hidden;
        `
      : css`
          height: ${contentRect.client.height}px;
          overflow: hidden;
          animation: 0s linear 0.3s forwards panel_wrapper__delayedShow;
        `;
}}
`;

export const CollapsiblePanelBody: React.ComponentType<CollapsiblePanelBodyProps> = withContentRect(
    ['client'],
)(({children, measureRef, ...props}) => (
    <CollapsiblePanelBodyWrapper {...props}>
      <PanelBody innerRef={measureRef}>{children}</PanelBody>
    </CollapsiblePanelBodyWrapper>
));

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 auto;
  word-break: break-word;
`;

const PanelHeaderWrapper = styled.div<{
  withStatus?: boolean;
  clickable?: boolean;
}>`
  display: flex;
  min-height: 3rem;
  align-items: center;
  margin: -0.5rem 0;
  position: relative;

  ${props =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  ${props =>
    props.withStatus &&
    css`
      padding-left: 2em;
    `}
`;

const Decoration = styled.div`
  display: flex;
`;

export function PanelHeader({
                              children = null,
                              decoration,
                              ...props
                            }: React.HTMLAttributes<HTMLDivElement> & {
  children?: React.ReactNode;
  withStatus?: boolean;
  clickable?: boolean;
  decoration?: React.ReactNode | string;
}) {
  return (
      <PanelHeaderWrapper {...props}>
        <HeaderContent>{children}</HeaderContent>
        {decoration && (
            <Decoration>
              {typeof decoration === 'string' ? <Glyph size={25} name={decoration}/> : decoration}
            </Decoration>
        )}
      </PanelHeaderWrapper>
  );
}

type CollapsiblePanelProps = {
  header: React.ReactElement<any>;
  defaultExpanded?: boolean;
  children?: React.ReactNode;
};

const AnimatedGlyph = styled(Glyph)`
  transition: 0.3s transform;
`;

export function CollapsiblePanel({
                                   header,
                                   defaultExpanded = false,
                                   children = null,
                                   ...props
                                 }: CollapsiblePanelProps) {
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
      <Panel {...props}>
        {React.cloneElement(header, {
          decoration: (
              <AnimatedGlyph
                  style={{transform: `rotate(${expanded ? 180 : 0}deg)`}}
                  size={25}
                  name="arrowDown"
              />
          ),
          onClick: () => {
            setExpanded(!expanded);
          },
        })}
        <CollapsiblePanelBody collapsed={!expanded}>{children}</CollapsiblePanelBody>
      </Panel>
  );
}

export const PanelTitle = styled.h2`
  font-size: 1.1rem;
  font-weight: 800;
  display: flex;
  align-items: center;
`;

export const PanelSubtitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #888;
`;

export const PanelStatus = styled.div`
  width: 1em;
  height: 1em;
  top: 50%;
  left: 0;
  margin-top: -0.5em;
  position: absolute;
`;

export default Panel;
