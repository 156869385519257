import { Button } from '@liquid-design/liquid-design-react';
import generate from 'nanoid/generate';
import React from 'react';
import styled from 'styled-components';

import LoadingSpinner from '@/components/LoadingSpinner';
import { t } from '@/i18n';
import { Logger } from '@/logger';

const log = new Logger({ module: 'DataLoader' });

interface DataLoaderProps {
  action: ({ signal }: { signal: AbortSignal }) => Promise<void>;
  dataLoaded: boolean;
  children: () => React.ReactNode;
  // Hides Loading Spinner and error message
  optional?: boolean;
  background?: boolean;
  inline?: boolean;
}

interface DataLoaderState {
  error: any;
  errorId: string;
  loading: boolean;
}

const CenteredText = styled.div`
  position: absolute;
  margin-bottom: 10px;
  height: 80px;
  margin-top: -40px;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
`;

export class DataLoader<T> extends React.Component<DataLoaderProps<T>, DataLoaderState> {
  state: DataLoaderState;
  abortController: AbortController;

  constructor(props: DataLoaderProps<T>) {
    super(props);

    this.state = {
      error: null,
      errorId: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.loadIfNecessary();
  }

  componentDidUpdate() {
    this.loadIfNecessary();
  }

  loadIfNecessary = () => {
    if (this.state.error) return;
    if (this.hasData()) return;
    if (this.state.loading) return;

    this.load();
  };

  componentWillUnmount() {
    if (this.abortController) this.abortController.abort();
  }

  load = async () => {
    this.setState({
      loading: true,
      error: null,
    });

    try {
      if (this.abortController) this.abortController.abort();
      this.abortController = new window.AbortController();
      await this.props.action({ signal: this.abortController.signal });
      // update redux
      this.setState({
        error: null,
      });

      log.info('Data loaded.');
    } catch (e) {
      // show error
      if (this.abortController.signal.aborted) return;

      const errorId = generate('123456789ABCDEFGHIJKLMNPQRSTUVWXYZ.-', 14);
      log.error('Could not load data.', e, { errorId });

      this.setState({
        error: e,
        errorId,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  hasData = () => {
    return this.props.dataLoaded;
  };

  wrapInline = (children: React.ReactNode) => {
    if (!this.props.inline) {
      return children;
    } else {
      return (
        <div
          style={{
            position: 'relative',
            height: '80px',
          }}
        >
          {children}
        </div>
      );
    }
  };

  render() {
    if (!this.props.optional) {
      if (!this.hasData()) {
        if (this.state.error) {
          return this.wrapInline(
            <CenteredText>
              <div
                style={{
                  marginBottom: '10px',
                }}
              >
                <p>
                  <b>{t('data_loader.error')}</b>
                </p>
                <p>
                  <b>{t('data_loader.contact_it_support', { id: this.state.errorId })}</b>
                </p>
              </div>
              <Button onClick={this.load}>{t('data_loader.retry')}</Button>
            </CenteredText>,
          );
        } else {
          return this.wrapInline(<LoadingSpinner />);
        }
      }
    }
    if (this.hasData()) {
      return this.props.children();
    }
    return null;
  }
}
