import {Header, Logo, Navigation} from '@liquid-design/liquid-design-react';
import gql from 'graphql-tag';
import jsonata from 'jsonata';
import React, {ChangeEvent, Fragment, useState} from 'react';
import styled from 'styled-components';

import {useConfig} from '@/hooks/config';
import {useDebounceCallback} from '@/hooks/debounce';
import {t} from '@/i18n';

import {HistoryRouterController} from './Router/HistoryRouterController';
import {AppConfig} from '@/reducers/AppReducer';
import {cachedExpression} from '@/utils/queryUtils';

import {useImageLoader} from "@/components/useImageLoader";
import {useQuery} from "react-apollo-hooks";

const MIN_SEARCH_LENGTH = 3;

export interface IExplorerNavbarProps {
  // state
  children?: React.ReactNode;
}

export interface IExplorerNavbarState {
  menuOpen: boolean;
}

const FixedNavigation = styled(Navigation)`
  position: fixed;
  left: 0;
  top: 60px;
  bottom: 0;
  div:first-child {
   min-height: 0px;
  }
  background: ${props => props.theme.colors.white.base};

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  @media (max-width: 700px) {
    display: none;
  }
`;

const FixedHeader = styled(Header)`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;

  .LD__Logo{
    margin-bottom: 0;
  }
`;

const Content = styled.div`
  padding: ${60 + 20}px 1rem 0 ${80 + 20}px;

  @media (max-width: 700px) {
    padding: ${60 + 20}px 1rem 0;
  }
`;

function Sidebar({config}: { config: AppConfig }) {
  const userInfoQuery = useQuery(gql(config.userInfo._query));

  const fullNameExpression = jsonata(config.userInfo.fullName);
  const fullName = userInfoQuery.data && fullNameExpression.evaluate(userInfoQuery.data);

  const uuid =
      userInfoQuery.data && cachedExpression(config.userInfo.uuid).evaluate(userInfoQuery.data);

  const tabs = Array(
      {
        title: t('navbar.my_view'),
        iconName: 'person',
        onClick: () => {
          HistoryRouterController.pushRoute('identities/me');
        },
      },
  );

  tabs.push(
      {
        title: t('navbar.faq_loom_eva'),
        iconName: 'dashboard',
        onClick: () => {
          window.open('https://evarooms.merckgroup.com/Topic/EUS/en-us/products-services/other-tools-products/loom');
        },
      }
  );

  tabs.push(
      {
        title: t('navbar.credential_portal'),
        iconName: 'keys',
        onClick: () => {
          window.open('https://me.merckgroup.com/cp/');
        },
      }
  );

  tabs.push(
      {
        title: t('navbar.entitlement_portal'),
        iconName: 'people',
        onClick: () => {
          window.open('https://entitlement.giam.merckgroup.com/');
          },
      }
  );


    const url = useImageLoader(uuid, "120x120")
    return (
        <FixedNavigation
            title={fullName || ''}
            iconUrl={url}
            tabs={tabs}
            url={url}
        />
    );
}

const NonGxPLink = styled.a`
  color:white;
`;

/**
 * The explorer specific navbar.
 */
function ExplorerNavbar({children = null}: IExplorerNavbarProps) {
  const config = useConfig();
  const [searchTerm, setSearchTerm] = useState('');

  const updateDebouncedTerm = useDebounceCallback<string>(value => {
    navigateToSearch(value);
  });

  const navigateToSearch = (term: string) => {
    if (term.length < MIN_SEARCH_LENGTH) {
      return;
    }

    HistoryRouterController.pushRoute(`search?q=${encodeURIComponent(term)}`);
  };

  const searchTermChanged = (term: string) => {
    setSearchTerm(term);
    updateDebouncedTerm(term);
  };

  return (
      <Fragment>
        <Content>{children}</Content>
        <FixedHeader style={{

          zIndex: 2/*needet bacause lequid design elements clips throuw header*/
        }}
                     logoComponent={
                       <Logo
                           onClick={(e: MouseEvent) => {
                             if (e.ctrlKey || e.metaKey) {
                               window.open(window.location.origin, "_blank");
                             } else {
                               HistoryRouterController.pushRoute('');
                             }
                           }}
                           alt={t('common.app_name')}
                           color="secondary.base"
                           size={50}
                       />
                     }
                     searchBarPlaceholder="Search for Person..."
                     onChange={(e: ChangeEvent<HTMLInputElement>) => searchTermChanged(e.target.value)}
                     onSubmit={() => navigateToSearch(searchTerm)}
                     withText
                     labelOne={<NonGxPLink target="_blank"  rel="nofollow"  href="https://evarooms.merckgroup.com/Topic/GxP/">non-GxP system</NonGxPLink>}
        />
        {config && <Sidebar config={config}/>}
      </Fragment>
  );
}

export default ExplorerNavbar;
