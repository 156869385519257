import {WatchQueryOptions} from "apollo-client";
import {useEffect, useRef, useState} from "react";
import {useApolloClient} from "react-apollo-hooks";


export function useQuery(options: WatchQueryOptions) {
    const client = useApolloClient();
    const query = useRef(
        client.watchQuery({
            // do not fail on errors
            errorPolicy: 'all',
            ...options,
        }),
    );
    const [result, setResult] = useState(query.current.currentResult());

    useEffect(() => {
        query.current.setOptions(options);
    }, [{...options}]);

    useEffect(() => {
        // subscribe to query changes
        const subscription = query.current.subscribe(updatedResult => {
            setResult({...updatedResult});
        });

        // return cleanup function
        return function cleanup() {
            subscription.unsubscribe();
        };
    }, []);

    return {
        ...result,
        fetchMore: query.current.fetchMore.bind(query.current) as typeof query.current.fetchMore,
        refetch: query.current.refetch.bind(query.current),
    };
}

